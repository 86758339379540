import React, { useState } from "react";
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const HotlistVendor = () => {

    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    
    const [formData, setFormData] = useState({
        vendorfile:'',
        admid: userdetails.admid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const invalidfeedback1 = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px',
    };

    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
        
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.fname]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        let vendorfile = e.target.vendorfile.files[0];

       
        try {
            const response = await axios.post(`https://api.transnational.ai/api/imporhotlistvendors`, {vendorfile:vendorfile}, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            console.log(response.data);
            
            setErrors({});
            setSuccess(response.data.message);
        } catch (error) {
            //console.log(error.response.status)
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            }else if (error.response && error.response.status === 401) {
                setError(error.response.data.errormessage);
            } else {
                console.error('Error submitting form:', error);
                
            }
        }
    };

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Hotlistdashboard">Hotlist Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span>Import Hotlist Vendors</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        { error !== '' && <div className="alert alert-danger">{error}</div> }
                                        <form id="addemployee" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
                                        <tr>                                      
											<td><span className="label_normal">Import Excel File*</span></td>
											<td>:</td>
											<td><input type="file" name="vendorfile"  onChange={handleChange}/></td>
                                            <td>{errors.vendorfile && <span style={invalidfeedback}>{errors.vendorfile}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addemployee" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> 
                                        <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        </section>
    );
}

export default HotlistVendor;