import React from "react";
import { a } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import goto from "../images/goto.png";


const tdn = {
  textDecoration:"none"
  };
  
  function Footer() {
      return (
         <>
         <section style={{ background: '#004578',height:'250px',color: '#fff' }}>
          <div className="container">
            <footer className="col-md-12" style={{ width:'90%',marginLeft:'50px', }} >
              <div className="row" >
                <div className="col-md-3" style={{ marginTop:'20px' }}>
                  <h6><b>ABOUT COMPANY</b></h6>
                  <Link to="/Aboutus"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;About us</span></Link><br/>
                  <Link to="/Vision"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Vision & Mission</span></Link><br/>
                  <Link to="/Global"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Global Presence</span></Link><br/>
                  <Link to="/Corporatesocial"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Corporate Social Responsibility</span></Link><br/>
                </div>
                <div className="col-md-3" style={{ marginTop:'20px' }}>
                  <h6><b>SERVICES</b></h6>
                  <Link to="/Solutionintegration"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Solution Integration</span></Link><br/>
                  <Link to="/Appdevelopment"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;App Development & Management</span></Link><br/>
                  <Link to="/Consulting"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Workforce Solutions</span></Link><br/>
                  <Link to="/Managedservices"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Managed Services</span></Link><br/>
                </div>
                <div className="col-md-3" style={{ marginTop:'20px' }}>
                  <h6><b>CAREERS</b></h6>
                  <Link to="/Whytransnational"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Why Transnational</span></Link><br/>
                  <Link to="/Whatweoffer"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;What we offer</span></Link><br/>
                  <Link to="/Lifeculture"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Life & Culture</span></Link><br/>
                  <Link to="/Currentopenings"  style={tdn}><span style={{ fontSize :'9px', color:'#ffffff', fontSize:'15px', marginRight:'5px'}}><i className="bi bi-chevron-right" ></i>&nbsp;Current Openings</span></Link><br/>
                </div>
                <div className="col-md-3" style={{ marginTop:'20px' }}>
                  <h6><b>Follow us on </b></h6>
                  <div style={{ fontSize:'2.5em'}}>
                    <a href="#" role="button" data-toggle="modal" style={{ display:'inline-block',width :'1em',textDecoration:"none"}}> <i className="bi bi-rss" style={{ color:'#ffffff'}}> </i> </a>&nbsp;
                    <a href="http://www.facebook.com/pages/Apemploymentexchange/292146930902118?created" role="button" data-toggle="modal" style={{ display:'inline-block', width :'1em',textDecoration:"none"}}> <i className="fa fa-facebook" style={{ color:'#ffffff'}}></i></a>
                    <a href="https://twitter.com/apemploymentexc" role="button" data-toggle="modal" title="" style={{ display:'inline-block',width :'1em',textDecoration:"none"}}><i className="bi bi-twitter" style={{ color:'#ffffff'}}></i></a>&nbsp;
                    <a href="#" title="" style={{ display:'inline-block',width :'1em',textDecoration:"none"}}> <i className="bi bi-google" style={{ color:'#ffffff'}}> </i> </a>&nbsp;
                    <a href="#aedin" role="button" data-toggle="modal" style={{ display:'inline-block',width :'1em',textDecoration:"none"}}> <i className="bi bi-linkedin" style={{ color:'#ffffff'}}> </i> </a>
                  </div>
                </div>
  
              </div>
              <p style={{ padding :'30px 0', float : 'left', color: '#ffffff'}}>&copy; 2007 - 2023, Transnational Software Services Pvt Ltd, All rights reserved </p>
              <p style={{float: 'right', padding:'26px 25px', color: '#ffffff' }}><a href="/termsofuse" style={{padding: '0 20px',textDecoration:"none",color:'#ffffff'}}>Terms of use</a> | <a href="/privacypolicy" style={{padding: '0 20px',textDecoration:"none",color: '#ffffff'}}>Privacy Policy</a>
              </p>
              
            </footer>
          </div>
        </section>
        <a href="#" className="btn2" style={{ position: 'fixed', bottom: '38px', right: '10px', display:'none'}}  id="toTop">
        <img src={goto} width="60" height="60" alt=""/></a>
         </>     
          
      );
  }
  
  export default Footer;