import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import privacypolicy from "../images/banner/privacypolicy.png";

function Privacypolicy() {

    const servicesStyle = {
        backgroundImage: `url(${privacypolicy})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
        
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle} >
                  <div class="container" >
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'1000px', padding : '40px'}}>
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Privacy Policy</p>
                                      <p style={{ textAlign:"justify"}}>Transnational Software Services (P) Ltd is sensitive to privacy issues on the Internet and in general has no objection to the User accessing its website without providing any personal information. However, there are circumstances like those listed below, when Transnational may request you to provide your personal information.</p>
                                          
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Collection of personal information for propagating business</p>
                                      <p style={{ textAlign:"justify"}}>In some circumstances, Transnational may request for some personal information from you, like your name, e-mail address, company name, or telephone number. You are at liberty to respond or not to respond to these inquiries and any and all information provided is strictly voluntary. Transnational uses this information to get valuable feedback from your experience on our Web site. In addition, Transnational may also use your personal information for its other business purposes or in order to help serve you better, be it by informing you of new services, products, alliances etc. that could help you in your business. You shall not claim that any information which is voluntary posted by you on this web-site is confidential and/or proprietary.</p>						
                                      <p style={{ textAlign:"justify"}}>In general, you can visit our site without disclosing any personal information. However, there are areas of this site that require this information to complete certain functions, and may not be available to those choosing not to reveal the information requested. You recognize and understand that there is no compulsion on you to provide us with your personal information and any and all personal information provided by you to us is with your full consent, own volition and desire to provide such personal information. You also understand that we are under no obligation to verify the source from which the personal information about you is provided to us, and they are deemed to be provided by you.</p>
                                          
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Collecting information for on-line surveys</p>
                                      <p style={{ textAlign:"justify"}}>At times, we conduct on-line surveys to better understand the needs and profile of our visitors. When we conduct a survey, we will try to let you know how we will use the information at the time we collect information from you on the Internet. If you are not happy with the reasons of the survey you may refrain from contributing to it.</p>
                                          
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Collecting domain information</p>
                                      <p style={{ textAlign:"justify"}}>Transnational collects domain information and not the email addresses of its visitors as part of its analysis of the use of this site. This data enables us to better know which customers visit our site, how often they visit, and what parts of the site they visit most often. Transnational uses this information to improve its Web-based offerings. This information is collected automatically and requires no action on your part. By accessing our site you grant your unconditional consent to allow Transnational to collect your domain names.</p>
                                          
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Disclosure to third parties</p>
                                      <p style={{ textAlign:"justify"}}>In cases where we believe that business interests will be served, Transnational may share your information with its Business Partners, who can alert you about new products and services to improve your competitive edge. Upon receipt of any alert, if you desire to be removed from such an alert list, you may inform the sender or unsubscribe from the list as provided in each mail alert. However, you shall not be entitled to treat such alerts as unsolicited, nor shall you be entitled to take any action against Transnational or its Business Partners vis-à-vis any such alerts.</p>
                                      <p style={{ textAlign:"justify"}}>We may also be required to disclose your personal information in the event of a legal proceeding, court process, investigation or any such other process where we are required to do so by applicable law or where we have to establish our legal rights or where disclosure is required to prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person etc. We may also be required to disclose your personal information in the event of a business reorganization, amalgamation or merger.</p>
                                          
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Use of Cookies</p>
                                      <p style={{ textAlign:"justify"}}>Some pages on this site use "cookies," which are small files that the site places on your hard drive for identification purposes. These files are used for site registration and customization the next time you visit us. Your Web browser may allow you to be notified when you are receiving a cookie, giving you the choice to accept it or not. By not accepting cookies, some pages may not fully function and you may not be able to access certain information on this site.</p>
                                          
                                      <p style={{ textAlign:"justify"}}>Our site may contain links to other sites such as our partners and affiliates. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content or the privacy practices employed by other sites.</p>
                                          
                                      <p style={{ textAlign:"justify"}}>Transnational reserves the right to change, modify, or update this statement at any time without notice.</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Privacypolicy