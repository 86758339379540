import React, { useState } from "react";
import { Link } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import profilepic from "../../images/8989.jpg";
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};



function Profile() {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px',padding:'10px' }}>
                <div className="container">
                    <div className="col-md-12" style={{ margin:'0 auto', backgroundColor:'#fff'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <div className="container" style={{ minHeight:'400px',padding:'10px'}}>
                                        <section id="breadcrumbs" className="breadcrumb">
                                            <div  className="col-md-12 breadcrumbs" >
                                                <a href="/executive/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <span>Profile</span>
                                            </div>
                                        </section>
                                        <div className="contentbox" >
                                            <div className="contentheading" style={{ color:'#fff'}}>{userdetails.name} {userdetails.lname}</div>
                                            <div className="contentbody" style={{ width:'95%',marginTop:'50px',marginLeft:'100px'}}>
                                                <div className="row">
                                                    <div className="col-md-3" align="center"><img alt="User Pic" src={profilepic} className="img-circle img-responsive" width="120" height="120" /></div>
                                                    <div className="col-md-7">
                                                        <table className="table table-user-information" >
                                                            <tbody>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>{userdetails.name} {userdetails.lname}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Phone</td>
                                                                    <td>{userdetails.phone}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>{userdetails.email}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Profile;