import React from "react";
import { BrowserRouter as Router,Routes,useNavigate,Route,Link } from 'react-router-dom';
import tnslogo from '../../images/TNSS-Logo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import AuthUser from '../AuthUser';


import Homepage from '../Homepage';
import Sliderpart from "../Sliderpart";
import Homeoverview from "../Homeoverview";
import Servicespart from "../Servicespart";
import Aboutus from '../Aboutus'
import Vision from '../Vision';
import Global from '../Global';
import Corporatesocial from '../Corporatesocial';
import Solutionintegration from '../Solutionintegration';
import Appdevelopment from '../Appdevelopment';
import Consulting from '../Consulting';
import Managedservices from '../Managedservices';
import Whytransnational from '../Whytransnational';
import Whatweoffer from '../Whatweoffer';
import Lifeculture from '../Lifeculture';
import Termsofuse from '../Termsofuse';
import Privacypolicy from '../Privacypolicy';
import Contactus from '../Contactus';
import Footer from '../Footer';
import Currentopenings from "../Currentopenings";
import Viewjob from "../Viewjob";
import Referrals from "../Referrals";
import Referral from "../Referral";
import Globaladmin from "../Globaladmin"
import Dashboard from "../admin/Dashboard";
import Hrdashboard from "../admin/Hrdashboard";
import Rcdashboard from "../admin/Rcdashboard";
import Consultantdashboard from "../admin/Consultantdashboard"; 
import Hotlistdashboard from "../admin/Hotlistdashboard";
import Managerdashboard from "../admin/Managerdashboard";
import Vendordashboard from "../admin/Vendordashboard";
import Dmsdashboard from "../admin/Dmsdashboard";
import Clientdashboard from "../admin/Clientdashboard";
import Changepassword from "../admin/Changepassword";
import Createhr from "../admin/Createhr";
import Hrs from "../admin/Hrs";
import Createemployee from "../admin/Createemployee";
import Employees from "../admin/Employees";
import Createrc from "../admin/Createrc";
import Resourcecoordinators from "../admin/Resourcecoordinators";
import Editrc from "../admin/Editrc";
import Addvendor from "../admin/Addvendor";
import Vendors from "../admin/Vendors";
import Createmanager from "../admin/Createmanager";
import Managers from "../admin/Managers";
import Editmanager from "../admin/Editmanager";
import Createhconsultant from "../admin/Createhconsultant";
import Hconsultants from "../admin/Hconsultants";
import Edithconsultant from "../admin/Edithconsultant";
import Createhvendor from "../admin/Createhvendor";
import Hvendors from "../admin/Hvendors";
import Edithvendor from "../admin/Edithvendor";
import Createexecutive from "../admin/Createexecutive";
import Executives from "../admin/Executives";
import Clients from "../admin/Clients";
import Requirements from "../admin/Requirements";
import Reqcandidates from "../admin/Reqcandidates";
import Execclients from "../admin/Execclients";
import Addagreement from "../admin/Addagreement";
import Agreements from "../admin/Agreements";
import Empagreement from "../admin/Empagreement";
import Adddocument from "../admin/Adddocument";
import Editemployee from "../admin/Editemployee";
import Createconsultant from "../admin/Createconsultant";
import Consultants from "../admin/Consultants";
import Editconsultant from "../admin/Editconsultant";
import Submissions from "../admin/Submissions";
import Pendingconsultants from "../admin/Pendingconsultants";
import Vendorslist from "../admin/Vendorslist";
import Asigntoconsultant from "../admin/Asigntoconsultant";
import Hotlistvendor from "../admin/HotlistVendor";




function Adminheader(props) {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    //let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(usertype !== undefined){
            logout();
        }
    }
    
    return (
      <>
        <section style={{ background: '#f3f2f1' }}>
          <div style={{ height: '40px', width: '100%', background: '#004578'}}>
              <div className="container">
                  <img className="brand" style={{ padding : '0px', display:'block', position:'absolute', zIndex: '600' }} src={tnslogo} alt=""/>
                  <div style={{ width:'250px', float:'right' }}>
                      <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic"> Welcome {usertype!=='' && userdetails.fname}</Dropdown.Toggle>
                          <Dropdown.Menu>
                          <Dropdown.Item href="/admin/Dashboard">Dash Board</Dropdown.Item>
                          <Dropdown.Item href="/admin/Changepassword">Change Password</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={logoutUser}>Logout</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  </div>                    
              </div>
          </div>
          <div className="container">
              <div className="topmenu" style={{marginTop:'0px', marginLeft:'830px'}}>
                  <div className="container">
                      <div id='cssmenu'>
                          <ul>
                          <li className='active has-sub'><a href='/Aboutus'><span>ABOUT US</span></a></li>
                          <li className='active has-sub'><a href='#'><span>SERVICES</span></a>
                              <ul>
                              <li className='has-sub'><a href='/Solutionintegration'><span>Solution Integration</span></a></li>
                              <li className='has-sub'><a href='/Appdevelopment'><span>App Development & Management</span></a></li>
                              <li className='has-sub'><a href='/Consulting'><span>Workforce Solutions</span></a></li>
                              <li className='has-sub'><a href='/Managedservices'><span>Managed Services</span></a></li>
                              </ul>
                          </li>
                          <li className='active has-sub'><a href='#'><span>CAREERS</span></a>
                              <ul>
                              <li className='has-sub'><a href='/Whytransnational'><span>Why Transnational</span></a></li>
                              <li className='has-sub'><a href='/Whatweoffer'><span>What we offer</span></a></li>
                              <li className='has-sub'><a href='/Lifeculture'><span>Life & Culture</span></a></li>
                              <li className='has-sub'><a href='Currentopenings'><span>Current Openings</span></a></li>
                              <li className='has-sub'><a href='/Referrals'><span>EMPLOYEE REFERRAL PROGRAM</span></a></li>
                              </ul>
                          </li>
                          <li className='active has-sub'><a href='/Contactus'><span>CONTACT US</span></a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
        </section>
      
      <Routes>
      <Route exact path="/" element={<Homepage />} />
        <Route exact path="/Sliderpart" element={<Sliderpart />} />
        <Route exact path="/Homeoverview" element={<Homeoverview />} />
        <Route path="/Servicespart" element={<Servicespart />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Appdevelopment" element={<Appdevelopment />} />
        <Route path="/Appdevelopment" element={<Appdevelopment />} />
        <Route path="/Vision" element={<Vision />} />
        <Route path="/Global" element={<Global />} />
        <Route path="/Corporatesocial" element={<Corporatesocial />} />
        <Route path="/Solutionintegration" element={<Solutionintegration />} />
        <Route path="/Consulting" element={<Consulting />} />
        <Route path="/Managedservices" element={<Managedservices />} />
        <Route path="/Whytransnational" element={<Whytransnational />} />
        <Route path="/Whatweoffer" element={<Whatweoffer />} />
        <Route path="/Lifeculture" element={<Lifeculture />} />
        <Route path="/Termsofuse" element={<Termsofuse />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Currentopenings" element={<Currentopenings />} />
        <Route path="/Viewjob/:id" element={<Viewjob/>} />
        <Route path="/Referrals" element={<Referrals/>} />
        <Route path="/Referral/:id" element={<Referral/>} />
        <Route exact path="/Globaladmin" element={<Globaladmin />} />
        <Route exact path="/admin/Dashboard" element={<Dashboard />} />
        <Route path="/admin/Hrdashboard" element={<Hrdashboard/>} />
        <Route path="/admin/Rcdashboard" element={<Rcdashboard/>} />
        <Route path="/admin/Consultantdashboard" element={<Consultantdashboard/>} />
        <Route path="/admin/Hotlistdashboard" element={<Hotlistdashboard/>} />
        <Route path="/admin/Managerdashboard" element={<Managerdashboard/>} />
        <Route path="/admin/Vendordashboard" element={<Vendordashboard/>} />
        <Route path="/admin/Dmsdashboard" element={<Dmsdashboard/>} />
        <Route path="/admin/Clientdashboard" element={<Clientdashboard/>} />
        <Route path="/admin/Changepassword" element={<Changepassword/>} />
        <Route path="/admin/Createhr" element={<Createhr/>} />
        <Route path="/admin/Hrs" element={<Hrs/>} />
        <Route path="/admin/Createemployee" element={<Createemployee/>} />
        <Route path="/admin/Employees" element={<Employees/>} />
        <Route path="/admin/Editemployee/:id" element={<Editemployee/>} />
        <Route path="/admin/Createrc" element={<Createrc/>} />
        <Route path="/admin/Resourcecoordinators" element={<Resourcecoordinators/>} />
        <Route path="/admin/Editrc/:id" element={<Editrc/>} />
        <Route path="/admin/Addvendor" element={<Addvendor/>} />
        <Route path="/admin/Vendors" element={<Vendors/>} />
        <Route path="/admin/Createmanager" element={<Createmanager/>} />
        <Route path="/admin/Managers" element={<Managers/>} />
        <Route path="/admin/Editmanager/:id" element={<Editmanager/>} />
        <Route path="/admin/Createhconsultant" element={<Createhconsultant/>} />
        <Route path="/admin/Hconsultants" element={<Hconsultants/>} />
        <Route path="/admin/Edithconsultant/:id" element={<Edithconsultant/>} />
        <Route path="/admin/Createhvendor" element={<Createhvendor/>} />
        <Route path="/admin/Hvendors" element={<Hvendors/>} />
        <Route path="/admin/Edithvendor/:id" element={<Edithvendor/>} />
        <Route path="/admin/Createexecutive" element={<Createexecutive/>} />
        <Route path="/admin/Executives" element={<Executives/>} />
        <Route path="/admin/Clients" element={<Clients/>} />
        <Route path="/admin/Requirements/:id" element={<Requirements/>} />
        <Route path="/admin/Reqcandidates/:id" element={<Reqcandidates/>} />
        <Route path="/admin/Execclients/:id" element={<Execclients/>} />
        <Route path="/admin/Addagreement" element={<Addagreement/>} />
        <Route path="/admin/Agreements" element={<Agreements/>} />
        <Route path="/admin/Empagreement/:id" element={<Empagreement/>} />
        <Route path="/admin/Adddocument/:id" element={<Adddocument/>} />
        <Route path="/admin/Createconsultant" element={<Createconsultant/>} />
        <Route path="/admin/Consultants" element={<Consultants/>} />
        <Route path="/admin/Editconsultant/:id" element={<Editconsultant/>} />
        <Route path="/admin/Submissions" element={<Submissions/>} />
        <Route path="/admin/Pendingconsultants" element={<Pendingconsultants/>} />
        <Route path="/admin/Vendorslist/:id" element={<Vendorslist/>} />
        <Route path="/admin/Asigntoconsultant" element={<Asigntoconsultant/>} />
        <Route path="/admin/Hotlistvendor" element={<Hotlistvendor/>} />
      </Routes>
      <Footer/>
      </>

    );
}

export default Adminheader;