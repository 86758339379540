import React from "react";
import { Routes, Route } from 'react-router-dom';
import "../css/header.css";
import tnslogo from "../images/TNSS-Logo.png";
import 'bootstrap/dist/css/bootstrap.css';

import Globaladmin from './Globaladmin';
import Homepage from "./Homepage";
import Sliderpart from "./Sliderpart";
import Homeoverview from "./Homeoverview";
import Servicespart from "./Servicespart";
import Footer from "./Footer";
import Aboutus from "./Aboutus";
import Appdevelopment from "./Appdevelopment";
import Vision from "./Vision";
import Global from "./Global";
import Corporatesocial from "./Corporatesocial";
import Solutionintegration from "./Solutionintegration";
import Consulting from "./Consulting";
import Managedservices from "./Managedservices";
import Whytransnational from "./Whytransnational";
import Whatweoffer from "./Whatweoffer";
import Lifeculture from "./Lifeculture";
import Termsofuse from "./Termsofuse";
import Privacypolicy from "./Privacypolicy";
import Contactus from "./Contactus";
import Currentopenings from "./Currentopenings";
import Viewjob from "./Viewjob";
import Referrals from "./Referrals";
import Referral from './Referral';
import Consultantregister from "./Consultantregister";
import Adminforgotpassword from "./Adminforgotpassword";
import Adminresetpassword from "./Adminresetpassword";
import Hrforgotpassword from "./Hrforgotpassword";
import Hrresetpassword from "./Hrresetpassword";
import Rcforgotpassword from "./Rcforgotpassword";
import Rcresetpassword from "./Rcresetpassword";
import Consultantforgotpassword from "./Consultantforgotpassword";
import Consultantresetpassword from "./Consultantresetpassword";
import Executiveforgotpassword from "./Executiveforgotpassword";
import Executiveresetpassword from "./Executiveresetpassword";

function Header(props) {
  return (
    <>
      <section style={{ background: '#f3f2f1' }}>
        <div style={{ height: '40px', width: '100%', background: '#004578'}}>
            <div className="container">
                <img className="brand" style={{ padding : '0px', display:'block', position:'absolute', zIndex: '600' }} src={tnslogo} alt=""/>
                <a href="/Globaladmin" style={{float: 'right', padding: '10px', fontWeight:'bold', color: '#fff', textDecoration:"none" }}>
                <i className="fa fa-sign-in" aria-hidden="true" style={{ marginRight: '10px'}}></i>EMPLOYEE LOGIN
                </a>
            </div>
        </div>
        <div className="container">
            <div className="topmenu" style={{marginTop:'0px', marginLeft:'830px'}}>
                <div className="container">
                    <div id='cssmenu'>
                        <ul>
                        <li className='active has-sub'><a href='/Aboutus'><span>ABOUT US</span></a></li>
                        <li className='active has-sub'><a href='#'><span>SERVICES</span></a>
                            <ul>
                            <li className='has-sub'><a href='/Solutionintegration'><span>Solution Integration</span></a></li>
                            <li className='has-sub'><a href='/Appdevelopment'><span>App Development & Management</span></a></li>
                            <li className='has-sub'><a href='/Consulting'><span>Workforce Solutions</span></a></li>
                            <li className='has-sub'><a href='/Managedservices'><span>Managed Services</span></a></li>
                            </ul>
                        </li>
                        <li className='active has-sub'><a href='#'><span>CAREERS</span></a>
                            <ul>
                            <li className='has-sub'><a href='/Whytransnational'><span>Why Transnational</span></a></li>
                            <li className='has-sub'><a href='/Whatweoffer'><span>What we offer</span></a></li>
                            <li className='has-sub'><a href='/Lifeculture'><span>Life & Culture</span></a></li>
                            <li className='has-sub'><a href='Currentopenings'><span>Current Openings</span></a></li>
                            <li className='has-sub'><a href='/Referrals'><span>EMPLOYEE REFERRAL PROGRAM</span></a></li>
                            </ul>
                        </li>
                        <li className='active has-sub'><a href='/Contactus'><span>CONTACT US</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/Sliderpart" element={<Sliderpart />} />
        <Route exact path="/Homeoverview" element={<Homeoverview />} />
        <Route path="/Servicespart" element={<Servicespart />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Appdevelopment" element={<Appdevelopment />} />
        <Route path="/Appdevelopment" element={<Appdevelopment />} />
        <Route path="/Vision" element={<Vision />} />
        <Route path="/Global" element={<Global />} />
        <Route path="/Corporatesocial" element={<Corporatesocial />} />
        <Route path="/Solutionintegration" element={<Solutionintegration />} />
        <Route path="/Consulting" element={<Consulting />} />
        <Route path="/Managedservices" element={<Managedservices />} />
        <Route path="/Whytransnational" element={<Whytransnational />} />
        <Route path="/Whatweoffer" element={<Whatweoffer />} />
        <Route path="/Lifeculture" element={<Lifeculture />} />
        <Route path="/Termsofuse" element={<Termsofuse />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Currentopenings" element={<Currentopenings />} />
        <Route path="/Viewjob/:id" element={<Viewjob/>} />
        <Route path="/Referrals" element={<Referrals/>} />
        <Route path="/Referral/:id" element={<Referral/>} />
        <Route path="/Consultantregister" element={<Consultantregister/>} />
        <Route exact path="/Globaladmin" element={<Globaladmin />} />
        <Route exact path="/Adminforgotpassword" element={<Adminforgotpassword />} />
        <Route exact path="/Adminresetpassword/:email/:token" element={<Adminresetpassword />} />
        <Route exact path="/Hrforgotpassword" element={<Hrforgotpassword />} />
        <Route exact path="/Hrresetpassword/:email/:token" element={<Hrresetpassword />} />
        <Route exact path="/Rcforgotpassword" element={<Rcforgotpassword />} />
        <Route exact path="/Rcresetpassword/:email/:token" element={<Rcresetpassword />} />
        <Route exact path="/Consultantforgotpassword" element={<Consultantforgotpassword />} />
        <Route exact path="/Consultantresetpassword/:email/:token" element={<Consultantresetpassword />} />
        <Route exact path="/Executiveforgotpassword" element={<Executiveforgotpassword />} />
        <Route exact path="/Executiveresetpassword/:email/:token" element={<Executiveresetpassword />} />
      </Routes>
      <Footer/>
    </>

  );
}

export default Header;