import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import deleteicon from "../../images/icon/delete.png";
import editicon from "../../images/icon/edit.png";
import pendingicon from "../../images/icon/pending.png";
import enableicon from "../../images/icon/enable.gif";
import disableicon from "../../images/icon/disable.gif";
import cenableicon from "../../images/icon/cenable.png";
import cdisableicon from "../../images/icon/cdisable.png";
import docicon from "../../images/icon/word_doc.jpg";
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Consultants() {

    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get("https://api.transnational.ai/api/consultants") 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.consultants);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    const handleFilterchange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRecords = records.filter((record) =>
        record.fname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDelete = async (id) => {
        try {
          axios.get(`https://api.transnational.ai/api/deleteconsultant/${id}`);
          setRecords(records.filter(record => record.id !== id));
        } catch (error) {
          console.error('Error deleting record:', error);
        }
    };

    const handleStatus = (id) => {
        axios.get(`https://api.transnational.ai/api/consultantstatus/${id}`);
        setRecords(records.map(record => record.id === id ? { ...record, status: record.status === 'Y' ? 'N' : 'Y' } : record));     
    }

    const handleCstatus = (id) => {
        axios.get(`https://api.transnational.ai/api/consultantcstatus/${id}`);
        setRecords(records.map(record => record.id === id ? { ...record, cstatus: record.cstatus === 'Y' ? 'N' : 'Y' } : record));     
    }

    const handleDownload = (id) => {
        axios({
            url: `https://api.transnational.ai/api/cdownloadfile/${id}`, 
            method: 'GET',
            responseType: 'blob', 
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error downloading file: ', error);
        });
        
    };

    const servicesStyle = {
        backgroundImage: `url(${admin})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    const getStatusImage = (status) => {
        switch (status) {
            case 'Y':
                return <img src={enableicon} alt="Active"  width={18} height={18} />;
            case 'N':
                return <img src={disableicon} alt="Inactive" width={18} height={18} />;
        }
    };
    const getCstatusImage = (cstatus) => {
        switch (cstatus) {
            case 'Y':
                return <img src={cenableicon} alt="Active"  width={18} height={18} />;
            case 'N':
                return <img src={cdisableicon} alt="Inactive" width={18} height={18} />;
            default:
                return <img src={pendingicon} alt="Inactive" width={18} height={18} />;
        }
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                    <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Consultantdashboard">Consultant Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span>Consultants</span>
                                    </div>
                                </section>
                                <div className="container">
                                    <div className='row'>
                                        <div className="col-9" style={{ marginLeft:'25px'}}>
                                                                                                                      
                                        </div>
                                        <div className="col-3" style={{ marginLeft:'-40px'}}>
                                            <input style={{ height:"35px",marginRight:"-5px",width:"255px"}} value={searchTerm} onChange={handleFilterchange} type='text' name='keyword' maxLength={150} placeholder='search consultant' />
                                            <button class="btn btn-warning" style={{ marginTop:"-5px",backgroundColor:"#ccc",border:"#ccc"}} type="button"><i class="bi bi-search" style={{ fontSize:"10px"}}></i></button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"-20px"}}>
                                    <h3 className="media-heading"></h3>
                                    
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Consultant Name</th>
                                        <th>Job Title</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Work Authorization</th>
                                        <th>State</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {filteredRecords.map(item => (
                                        <tr  key={item.id}>
                                        <td><Link to={`/admin/Vendorslist/${item.cid}`} >{item.fname} {item.lname}</Link></td>
                                        <td>{item.jobtitle}</td>
                                        <td>{item.email}</td>
                                        <td>{item.tel}</td>
                                        <td>{item.workauthorization}</td>
                                        <td>{item.state}</td>
                                        <td><button onClick={() => handleStatus(item.id)}>{getStatusImage(item.status)}</button>&nbsp;
                                            <button onClick={() => handleDownload(item.resume)}><img src={docicon} width={18} height={18}  /></button>&nbsp;
                                            <button onClick={() => handleCstatus(item.id)}>{getCstatusImage(item.cstatus)}</button>&nbsp;
                                            <Link to={`/admin/Editconsultant/${item.id}`} ><img src={editicon} width={18} height={18} style={{marginRight:'10px', marginLeft:'10px'}} /></Link>
                                            <button onClick={() => handleDelete(item.id)}><img src={deleteicon} /></button></td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Consultants