import React, { useState,useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Editconsultant = () => {
    let {id} = useParams();
    const [consultant,setConsultant]=useState({});
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/editconsultant/${id}`);
                setConsultant(response.data.consultant)
                console.log(response.data.consultant)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])


    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
    const [error, setError] = useState();
	const [success, setSuccess] = useState('');
    const [inputBox, setInputBox] = useState(false)
    function onChange(value) {
        if(value == "others") {
            setInputBox(true)
            console.log(inputBox)
        } 
    }

    const handleChange = (e) => {
        setConsultant({
            ...consultant,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		//console.log(rc);
        try {
            const response = await axios.post(`https://127.0.0.1:8000/api/updateconsultant/${id}`, consultant);
            console.log(response.data.consultant);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Consultantdashboard">Consultant Dashboard</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Consultants">Consultants</a> <i class="bi bi-chevron-double-right"></i> <span>Edit Consultant</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addemployee" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="250"><span className="label_normal">First Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="fname" maxLength={40} className="ui_text" value={consultant.fname}  onChange={handleChange} /></td>
                                            <td>{errors.fname && <span style={invalidfeedback}>{errors.fname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Last Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="lname" maxLength={40} className="ui_text" value={consultant.lname}  onChange={handleChange} /></td>
                                            <td>{errors.lname && <span style={invalidfeedback}>{errors.lname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Job Title*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="jobtitle" maxLength={40} className="ui_text" value={consultant.jobtitle}  onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Email*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="email" maxLength={40} className="ui_text" value={consultant.email} disabled  onChange={handleChange} /></td>
                                            <td></td>
										</tr>
                                        
                                        <tr>
											<td width="250"><span className="label_normal">Mobile*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="mobile" maxLength={40} className="ui_text" value={consultant.mobile} onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Alt. Tel# *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="tel" maxLength={40} className="ui_text" value={consultant.tel} onChange={handleChange} /></td>
                                            <td>{errors.tel && <span style={invalidfeedback}>{errors.tel[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Work Authorization *</span></td>
											<td width="10">:</td>
											<td width="250"><select size="1" style={{ width: "75%",height:'28px'}} name="workauthorization" id="Consultant_workauthorization" value={consultant.workauthorization} onChange={handleChange}>
                                                            <option value="">-- Work Authorization --</option>
                                                            <option value="CPT">CPT</option>
                                                            <option value="OPT EAD">OPT EAD</option>
                                                            <option value="HIB">HIB</option>
                                                            <option value="L2 EAD">L2 EAD</option>
                                                            <option value="J2 EAD">J2 EAD</option>
                                                            <option value="GC EAD">GC EAD</option>
                                                            <option value="GC">GC</option>
                                                            <option value="US Citizen">US Citizen</option>
                                                            <option value="Others">Others</option>
                                                            </select></td>
                                            <td>{errors.workauthorization && <span style={invalidfeedback}>{errors.workauthorization[0]}</span>}</td>
										</tr>
                                        <tr style={ inputBox ? {marginTop:'10px', display:'block'}  : {marginTop:'10px', display:'none'} }  id="others">
											<td><span className="label_normal"></span></td>
											<td>:</td>
											<td><input type="text" name="others" id="Consultant_others" maxLength={100} className="ui_text" value={consultant.others} onChange={handleChange} /></td>
                                            <td>{errors.others && <span style={invalidfeedback}>{errors.others[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Address*</span></td>
											<td>:</td>
											<td><textarea name="address" type="text" className="ui_text" value={consultant.address} onChange={handleChange}></textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">City*</span></td>
											<td>:</td>
											<td><input type="text" name="city" maxLength={20} className="ui_text" value={consultant.city} onChange={handleChange} /></td>
                                            <td>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">State*</span></td>
											<td>:</td>
											<td><select size="1" style={{ width:"75%",height:'28px'}} name="state" value={consultant.state} onChange={handleChange}>
                                                <option value="">Select State</option>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AZ">AZ</option>
                                                <option value="AR">AR</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="DC">DC</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="IA">IA</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="ME">ME</option>
                                                <option value="MD">MD</option>
                                                <option value="MA">MA</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MS">MS</option>
                                                <option value="MO">MO</option>
                                                <option value="MT">MT</option>
                                                <option value="NE">NE</option>
                                                <option value="NV">NV</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NY">NY</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WV">WV</option>
                                                <option value="WI">WI</option>
                                                <option value="WY">WY</option>
                                                </select></td>
                                            <td>{errors.state && <span style={invalidfeedback}>{errors.state[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Resume*</span></td>
											<td>:</td>
											<td><input type="file" multiple name="resume"  onChange={handleChange}/></td>
                                            <td>{errors.resume && <span style={invalidfeedback}>{errors.resume}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addemployee" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> 
                                        <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>  
        </section>
        </>
    );
}

export default Editconsultant;