import React, { useState,useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Editjobpost = () => {
    let {id} = useParams();
    const [jobpost,setJobpost]=useState({});
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/editjobpost/${id}`);
                setJobpost(response.data.jobpost)
                console.log(response.data.jobpost)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])


    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setJobpost({
            ...jobpost,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		//console.log(rc);
        try {
            const response = await axios.post(`https://api.transnational.ai/api/updatejobpost/${id}`, jobpost);
            //sessionStorage.setItem('userdetail', JSON.stringify(response.data.employee));
            console.log(response.data.jobpost);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/hr/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/hr/Jobposts">Job Posts</a> <i class="bi bi-chevron-double-right"></i> <a href={`/hr/Viewjobpost/${jobpost.id}`}>{jobpost.title}</a> <i class="bi bi-chevron-double-right"></i>  <span>Edit Job Post</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addvendor" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
                                        <tr>
											<td width="150"><span className="label_normal">Job Title *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="title" maxLength={255} className="ui_text" value={jobpost.title} onChange={handleChange} /></td>
                                            <td>{errors.title && <span style={invalidfeedback}>{errors.title[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Positions *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="positions" maxLength={40} className="ui_text" value={jobpost.positions} onChange={handleChange} /></td>
                                            <td>{errors.positions && <span style={invalidfeedback}>{errors.positions[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Company *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="company" maxLength={100} className="ui_text" value={jobpost.company} onChange={handleChange} /></td>
                                            <td>{errors.company && <span style={invalidfeedback}>{errors.company[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">URL</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="url" maxLength={100} className="ui_text" value={jobpost.url} onChange={handleChange} /></td>
                                            <td>{errors.url && <span style={invalidfeedback}>{errors.url[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Country *</span></td>
											<td width="10">:</td>
											<td width="250">
                                                <select size="1" style={{ width:"95%",height:'28px'}} name="country" onChange={handleChange} value={jobpost.country}>
                                                <option value="usa">United States of America</option>
                                                <option value="india">India</option>
                                                </select>
                                            </td>
                                            <td></td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">City *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="city" maxLength={40} className="ui_text" value={jobpost.city} onChange={handleChange} /></td>
                                            <td>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">State *</span></td>
											<td width="10">:</td>
											<td width="250">
                                                <select size="1" style={{ width:"95%",height:'28px'}} name="state" value={jobpost.state} onChange={handleChange}>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AZ">AZ</option>
                                                <option value="AR">AR</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="DC">DC</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="IA">IA</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="ME">ME</option>
                                                <option value="MD">MD</option>
                                                <option value="MA">MA</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MS">MS</option>
                                                <option value="MO">MO</option>
                                                <option value="MT">MT</option>
                                                <option value="NE">NE</option>
                                                <option value="NV">NV</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NY">NY</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WV">WV</option>
                                                <option value="WI">WI</option>
                                                <option value="WY">WY</option>
                                                </select>
                                            </td>
                                            <td></td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Zip Code *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="zipcode" maxLength={40} className="ui_text" value={jobpost.zipcode} onChange={handleChange} /></td>
                                            <td>{errors.zipcode && <span style={invalidfeedback}>{errors.zipcode[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Description *</span></td>
											<td>:</td>
											<td><textarea name="description"  className="ui_text" style={{ height:'100px'}} value={jobpost.description} onChange={handleChange}></textarea></td>
                                            <td>{errors.description && <span style={invalidfeedback}>{errors.description[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Education *</span></td>
											<td>:</td>
											<td><textarea name="education"  className="ui_text" style={{ height:'100px'}} value={jobpost.education} onChange={handleChange}></textarea></td>
                                            <td>{errors.education && <span style={invalidfeedback}>{errors.education[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Experience *</span></td>
											<td>:</td>
											<td><textarea name="experience"  className="ui_text" style={{ height:'100px'}} value={jobpost.experience} onChange={handleChange}></textarea></td>
                                            <td>{errors.experience && <span style={invalidfeedback}>{errors.experience[0]}</span>}</td>
										</tr>
										
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addvendor" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>   
            
        </section>
        </>
    );
}

export default Editjobpost;