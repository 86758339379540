import React, { useState,useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Createexecutive = () => {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
        
    const [formData, setFormData] = useState({
        firstname:'',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        reconfirmpassword: '',
        admid: userdetails.admid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    
    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const [checked, setChecked] = React.useState(true);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/createexecutive', formData,);
            console.log(response.data);
            setErrors({});
			setSuccess(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ }}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Clientdashboard">CT Group Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span>Create Executive</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addvendor" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
                                        <tr>
											<td width="180"><span className="label_normal">First Name *</span></td>
											<td width="10">:</td>
											<td width="550"><input type="text" name="name" maxLength={40} className="ui_text" value={formData.name} onChange={handleChange} /></td>
                                            <td>{errors.name && <span style={invalidfeedback}>{errors.name[0]}</span>}</td>
										</tr>
										<tr>
											<td width="180"><span className="label_normal">Last Name *</span></td>
											<td width="10">:</td>
											<td width="550"><input type="text" name="lname" maxLength={40} className="ui_text" value={formData.lname} onChange={handleChange} /></td>
                                            <td>{errors.lname && <span style={invalidfeedback}>{errors.lname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Email *</span></td>
											<td>:</td>
											<td><input type="text" name="email" maxLength={100} className="ui_text" value={formData.email} onChange={handleChange} /></td>
                                            <td>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</td>
                                        </tr>
										<tr>
											<td><span className="label_normal">Phone *</span></td>
											<td>:</td>
											<td><input type="text" name="phone" maxLength={20} className="ui_text" value={formData.phone} onChange={handleChange} /></td>
                                            <td>{errors.phone && <span style={invalidfeedback}>{errors.phone[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Choose Password *</span></td>
											<td>:</td>
											<td><input type="password" name="password" maxLength={50} className="ui_text" value={formData.password} onChange={handleChange} /></td>
                                            <td>{errors.password && <span style={invalidfeedback}>{errors.password[0]}</span>}</td>
										</tr>
										<tr>
											<td width="180"><span className="label_normal">Reconfirm Password *</span></td>
											<td width="10">:</td>
											<td width="550"><input type="password" name="reconfirmpassword" maxLength={40} className="ui_text" value={formData.reconfirmpassword} onChange={handleChange} /></td>
                                            <td>{errors.reconfirmpassword && <span style={invalidfeedback}>{errors.reconfirmpassword[0]}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addvendor" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Createexecutive;