import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import career4 from "../images/banner/career4.png";

export default class Referrals extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            error:null,
            isloaded:false,
            referrals:[]
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            const response = await fetch('https://127.0.0.1:8000/api/referrals');
            const data = await response.json(); // Assuming you're getting an array of objects with HTML content

            // Map through the data and strip HTML tags for each item
            const sanitizedData = data.map(item => {
                return {
                    ...item,
                    introduction: this.stripHtmlTags(item.introduction),
                };
            });

            this.setState({ referrals: sanitizedData });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    };
    

    

    render(){
        const { referrals } = this.state;
        const servicesStyle = {
            backgroundImage: `url(${career4})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor :'#e6f4ff',
            width :'100%',
            height: '33vh',
        };

        return (
            <section style={{ background :'#f4f4f4' }}>
                <section id="bannerSection" style={servicesStyle}>
                    <div className="container">
                        <h1 id="pageTitle"></h1>
                    </div>
                </section>
                <div className="container" style={{ background :'#ffffff' }}>
                    <div className="col-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <div className="media-body" style={{ minHeight : '500px' , padding : '40px'}}>
                                        <h3 className="media-heading">EMPLOYEE REFERRAL PROGRAM</h3>
                                        <table className="mytable">
                                        <thead>
                                            <tr>
                                            <th>Introduction</th>
                                            <th style={{ width: "100px"}}>Posted date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {referrals.map(item => (
                                            <tr  key={item.id}>
                                            <td><Link  to={`/Referral/${item.id}`}>{item.introduction}</Link></td>
                                            <td>{item.postingdate}</td>
                                            </tr>
                                        ))} 
                                        </tbody>
                                        </table>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
        
    }
}
