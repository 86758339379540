import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,useNavigate
} from "react-router-dom";
import Header from './components/Header';
import Adminheader from './components/admin/Adminheader';
import Executiveheader from './components/executive/Executiveheader';
import Hrheader from './components/hr/Hrheader';
import Consultantheader from "./components/consultant/Consultantheader";
import Rcheader from "./components/rc/Rcheader";
import AuthUser from "./components/AuthUser";


function App() {
  const myusertype = sessionStorage.getItem('usertype');
  const {getUsertype} = AuthUser();
  //let usertype = getUsertype()== 'admin' ? <Adminheader /> : <Header />;
  let usertype = getUsertype();
  if(usertype === 'executive'){
    return(
      <>
        <Executiveheader />
      </>
    );
  }else if(usertype === 'admin'){
    return(
      <>
        <Adminheader />
      </>
    );
  }else if(usertype === 'hr'){
    return(
      <>
        <Hrheader />
      </>
    );
  }else if(usertype === 'consultant'){
    return(
      <>
        <Consultantheader />
      </>
    );
  }else if(usertype === 'rc'){
    return(
      <>
        <Rcheader />
      </>
    );
  }else{
    return(
      <>
        <Header />
      </>
    );
  }
  
}

export default App;