import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import aboutus from "../images/banner/aboutus.png";
import Servicespart from "./Servicespart";

function Vision() {

    const aboutusStyle = {
        backgroundImage: `url(${aboutus})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={aboutusStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding : '5px 50px'}}>Transnational's Vision</h3>
                                      <p style={{ padding : '5px 50px', textAlign:"justify"}}>To be the leading global software solutions provider to the Telecom industry.</p>
                                  </div>
                              </li>
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding : '5px 50px'}}>Transnational's Mission Statement</h3>
                                      <p style={{ padding : '5px 50px'}}>To be the global leader in Outsourcing Services to the Telecom industry, building on our technologies, competencies and customer interests, and creating value for our customers.</p>
                                  </div>
                              </li>
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding: '5px 50px' }}>The Values that drive us</h3>
                                      <p style={{ padding: '5px 50px', textAlign: 'justify'}}>Transnational is focused on creating sustainable value growth through innovative solutions and unique partnerships. Our values are at the heart of our business reputation and are essential to our continued success. We foster an environment to instill these values in every facet of our organization.</p>
                                      <div style={{ padding: '5px 50px' }}>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Good corporate citizenship</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Professionalism</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Customer first</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Commitment to quality</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Dignity of the individual</p>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          <Servicespart />
          </>
      );
}

export default Vision