import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import career from "../images/banner/career.png";

function Whytransnational() {

    const servicesStyle = {
        backgroundImage: `url(${career})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 className="media-heading">Why Transnational</h3>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Reach out to the world and grow with Transnational. One of the best places to work for in the Telecom domain, Transnational promises you an exciting journey of growth & learning. A vibrant work place…an amazing genre of skilled professionals. It is the best breeding ground for talent all across the globe. Come explore the world of Transnational!!! Take a Giant Leap !!!</p>
                                                      
                                      <div>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Mature organization with quality practices and processes</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Elite Clientele</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          World class infrastructure</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Work on cutting edge tools & technologies</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Work with highly skilled professionals from all across the globe</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Skill up gradation through training and development</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Benefit from fast track career growth plan</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Gain from mentoring by seniors</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Experience employee empowerment</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Expand your work geographies through our global presence</p>
                                          <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Supportive and healthy work environment</p>
                                      </div>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>At Transnational it is a never ending journey…</p>	
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Whytransnational