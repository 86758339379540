import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import career3 from "../images/banner/career3.png";

function Lifeculture() {

    const servicesStyle = {
        backgroundImage: `url(${career3})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 className="media-heading">Life & Culture</h3>
                                      <p style={{ textAlign :'justify' }}>Abundant opportunities, great work culture, and an amazing set of people is what characterizes Transnational! Come explore Transnational!</p>
                                      <h3 className="media-heading">Employee Empowerment</h3>
                                      <p style={{ textAlign :'justify' }}>Transnational truly believes in employee empowerment and our endeavor is to make the organization a ‘second home', where every employee feels relaxed yet motivated to contribute and grow. Our various internal feedback mechanisms give every one an opportunity to voice their opinion and feedback. Our third party employee satisfaction survey “Darpan” gives one and all a choice to mirror their soul. The feedback gathered is assessed and issues raised are addressed.</p>
                                      <h3 className="media-heading">Robust Reward Program</h3>
                                      <p style={{ textAlign :'justify' }}>We encourage Quality and Merit and recognize it through our reward and recognition program. The idea is to foster individual growth both at the personal level, as part of a team and the organizational level at large.</p>
                                      <p style={{ textAlign :'justify' }}>Our unique reward program is pragmatic yet idealist as it endeavors to live up to the overall vision of the organization. In total we have thirteen different categories of awards.</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Lifeculture