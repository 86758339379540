import React, { useState,useEffect  } from "react";
import { Link,useParams } from 'react-router-dom';
import rc from "../../images/banner/rc.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${rc})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Updatevendor = () => {
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    let {id} = useParams();
    const [contactMethod, setContactMethod] = useState('');

    const [consultant, setCon] = useState({});
    const [vendor, setData] = useState({});
    const [formData, setFormData] = useState({
		cid: '',
        vendor: '',
        contactperson: '',
        phone: '',
        extension: '',
        email: '',
        city: '',
        state: '',
        jobtitle: '',
        description: '',
        clientname: '',
        status: '',
        others:'',
        requesttype:''
    });
    
    useEffect(() => {
        axios.get(`https://api.transnational.ai/api/updatevendor/${id}`)
        .then(response => {
            const retrievedData = response.data.vendor;
            const condate = response.data.consultant;
            setData(response.data.vendor);
            setCon(response.data.consultant);
            setFormData({
                cid: retrievedData.cid || '',
                vendor: retrievedData.vendor || '',
                contactperson: retrievedData.contactperson || '',
                phone: retrievedData.phone || '',
                extension: retrievedData.extension || '',
                email: retrievedData.email || '',
                city: retrievedData.city || '',
                state: retrievedData.state || '',
                jobtitle: retrievedData.jobtitle || '',
                description: retrievedData.description || '',
                clientname: retrievedData.clientname || '',
                status: retrievedData.status || '',
                others: retrievedData.others || '',
              });
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

        
    

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setContactMethod(e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
		let cid=formData.cid;
        let vendor=formData.vendor;
        let contactperson=formData.contactperson;
        let phone=formData.phone;
        let extension=formData.extension;
        let email=formData.email;
        let city=formData.city;
        let state=formData.state;
        let jobtitle=formData.jobtitle;
        let description=formData.description;
        let clientname=formData.clientname;
        let status=formData.status;
        let others=formData.others;
        let requesttype=e.target.requesttype.value;
        try {

            const response = await axios.post(`http://api.transnational.ai/api/updatevendor/${id}`, {cid:cid,vendor:vendor,contactperson:contactperson,phone:phone,extension:extension,
            email:email,city:city,state:state,jobtitle:jobtitle,clientname:clientname,description:description,status:status,others:others,requesttype:requesttype});
            console.log(response.data.name);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ }}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/rc/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/rc/Consultants">Consultants</a> <i class="bi bi-chevron-double-right"></i> <a href={`/rc/Vendors/${consultant.cid}`}>{consultant.fname} {consultant.lname}'s vendors</a> <i class="bi bi-chevron-double-right"></i> <span>Update Vendor</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="updatevendor" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
                                        <tr>
											<td width="150"><span className="label_normal">Consultant</span></td>
											<td width="10">:</td>
											<td width="250">
                                                <select size="1"  style={{ width:"95%",height:'28px'}} name="cid" onChange={handleChange}>
                                                <option key={id} value={consultant.cid}>
                                                    {consultant.fname}
                                                </option>
                                                </select>
                                            </td>
                                            <td></td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Vendor</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="vendor" maxLength={40} className="ui_text" value={formData.vendor} onChange={handleChange} /></td>
                                            <td>{errors.vendor && <span style={invalidfeedback}>{errors.vendor[0]}</span>}</td>
										</tr>
										<tr>
											<td width="150"><span className="label_normal">Contact Person</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="contactperson" maxLength={40} className="ui_text" value={formData.contactperson} onChange={handleChange} /></td>
                                            <td>{errors.contactperson && <span style={invalidfeedback}>{errors.contactperson[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Email</span></td>
											<td>:</td>
											<td><input type="text" name="email" maxLength={100} className="ui_text" value={formData.email} onChange={handleChange} /></td>
                                            <td>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</td>
                                        </tr>
										<tr>
											<td><span className="label_normal">Tel#</span></td>
											<td>:</td>
											<td><input type="text" name="phone" maxLength={20} className="ui_text" value={formData.phone} onChange={handleChange} /></td>
                                            <td>{errors.phone && <span style={invalidfeedback}>{errors.phone[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Extension</span></td>
											<td>:</td>
											<td><input type="text" name="extension" maxLength={50} className="ui_text" value={formData.extension} onChange={handleChange} /></td>
                                            <td>{errors.extension && <span style={invalidfeedback}>{errors.extension[0]}</span>}</td>
										</tr>
										<tr>
											<td width="150"><span className="label_normal">Client Name</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="clientname" maxLength={40} className="ui_text" value={formData.clientname} onChange={handleChange} /></td>
                                            <td>{errors.clientname && <span style={invalidfeedback}>{errors.clientname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Job Title</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="jobtitle" maxLength={40} className="ui_text" value={formData.jobtitle} onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Job Description</span></td>
											<td>:</td>
											<td><textarea name="description"  className="ui_text" style={{ height:'100px'}} value={formData.description} onChange={handleChange}>{formData.description}</textarea></td>
                                            <td>{errors.description && <span style={invalidfeedback}>{errors.description[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">City</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="city" maxLength={40} className="ui_text" value={formData.city} onChange={handleChange} /></td>
                                            <td>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">State</span></td>
											<td width="10">:</td>
											<td width="250">
                                                <select size="1" style={{ width:"95%",height:'28px'}} name="state" value={formData.state} onChange={handleChange}>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AZ">AZ</option>
                                                <option value="AR">AR</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="DC">DC</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="IA">IA</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="ME">ME</option>
                                                <option value="MD">MD</option>
                                                <option value="MA">MA</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MS">MS</option>
                                                <option value="MO">MO</option>
                                                <option value="MT">MT</option>
                                                <option value="NE">NE</option>
                                                <option value="NV">NV</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NY">NY</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WV">WV</option>
                                                <option value="WI">WI</option>
                                                <option value="WY">WY</option>
                                                </select>
                                            </td>
                                            <td></td>
										</tr>
                                        <tr>
											<td width="150"><span className="label_normal">Status</span></td>
											<td width="10">:</td>
											<td width="250">
                                                <select size="1" style={{ width:"95%",height:'28px'}} name="status" value={formData.status} onChange={handleChange}>
                                                <option value="submitted">Submitted</option>
                                                <option value="callrecieved">Call/Email recieved</option>
                                                <option value="vscreening">Vendor screening scheduled</option>
                                                <option value="cinterview">Client interview scheduled</option>
                                                <option value="fpending">Feedback pending</option>
                                                <option value="frecieved">Feedback recieved</option>
                                                <option value="notinterested">Not interested</option>
                                                <option value="phold">Profile on hold</option>
                                                <option value="pclosed">Position closed</option>
                                                <option value="others">Others</option>
                                                </select>
                                            </td>
                                            <td><input type="hidden" name="requesttype" value="update" /></td>
										</tr>
                                        {contactMethod === 'others' && (
                                        <tr>
                                            <td width="150"><span className="label_normal">Others</span></td>
                                            <td width="10">:</td>
                                            <td width="250"><input type="text" name="others" maxLength={40} className="ui_text" value={formData.others} onChange={handleChange} /></td>
                                            <td></td>
                                        </tr>
                                        )}
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="updatevendor" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Updatevendor;