import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import deleteicon from "../../images/icon/delete.png";
import enableicon from "../../images/icon/enable.gif"; 
import disableicon from "../../images/icon/disable.gif";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Jobposts(){

    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get("https://api.transnational.ai/api/jobposts") 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.jobposts);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    
    const handleDelete = async (id) => {
        try {
          axios.get(`https://api.transnational.ai/api/deletejob/${id}`);
          setRecords(records.filter(record => record.id !== id));
        } catch (error) {
          console.error('Error deleting record:', error);
        }
    };

    const handleStatus = (id) => {
        axios.get(`https://api.transnational.ai/api/jobpoststatus/${id}`);
        setRecords(records.map(record => record.id === id ? { ...record, status: record.status === 'Y' ? 'N' : 'Y' } : record));     
    }

    const getStatusImage = (status) => {
        switch (status) {
            case 'Y':
                return <img src={enableicon} alt="Active"  width={18} height={18} />;
            case 'N':
                return <img src={disableicon} alt="Inactive" width={18} height={18} />;
        }
    };

    const servicesStyle = {
        backgroundImage: `url(${hr})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                        <a href="/hr/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <span> Job Posts</span>
                                    </div>
                                </section>
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"30px"}}>
                                    <h3 className="media-heading"></h3>
                                    
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Job Title</th>
                                        <th>Location</th>
                                        <th>Posted Date</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {records.map(item => (
                                        <tr  key={item.id}>
                                        <td><Link  to={`/hr/Viewjobpost/${item.id}`}>{item.title}</Link></td>
                                        <td>{item.city} {item.state}</td>
                                        <td>{item.postingdate}</td>
                                        <td><button onClick={() => handleStatus(item.id)}>{getStatusImage(item.status)}</button>&nbsp;&nbsp;<button onClick={() => handleDelete(item.id)}><img src={deleteicon} width={18} height={18} /></button></td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Jobposts