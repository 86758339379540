import React, { useState, useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import tnsuslogo from "../../images/tnsuslogo.jpg";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Viewjobpost = () => {
    let {id} = useParams();
    const [referral,setViewjob]=useState({});
    
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/hrreferral/${id}`);
                setViewjob(response.data)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

       
    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/hr/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/hr/Hrreferrals">Referrals</a> <i class="bi bi-chevron-double-right"></i> <span> EMPLOYEE REFERRAL PROGRAM </span>
                                        </div>
                                    </section>
							        <div className="container" > 
                                        <div className="content" style={{ border:'none', border:'1px solid #ccc' }}>
                                            <table border={0} cellSpacing={5} cellPadding={7} >
                                            <tbody>
                                            <tr>
                                                <td width={200} valign={'top'}><div ><b>Date Posted </b></div></td>
                                                <td width={10} valign={'top'}> : </td>
                                                <td width={900}><div className={'content_bold'}>{referral.postingdate}</div></td>
                                            </tr>
                                            <tr>
                                                <td valign={'top'}><div><b>Job Title</b></div></td>
                                                <td width={10} valign={'top'}> : </td>
                                                <td><div className={'content_bold'}>{referral.introduction}</div></td>
                                            </tr>
                                            <tr>
                                                <td valign={'top'}><div><b>Eligibility</b></div></td>
                                                <td width={10} valign={'top'}> : </td>
                                                <td><div className={'content_bold'}>{referral.eligibility}</div></td>
                                            </tr>
                                            <tr>
                                                <td valign={'top'}><div><b>Incentives Offered</b></div></td>
                                                <td width={10} valign={'top'}> : </td>
                                                <td><div className={'content_bold'}>{referral.incentives}</div></td>
                                            </tr>
                                            <tr>
                                                <td valign={'top'}><div><b>Referral Procedure</b></div></td>
                                                <td width={10} valign={'top'}> : </td>
                                                <td><div className={'content_bold'}>{referral.referral}</div></td>
                                            </tr>
                                            
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td valign={'top'} align={'right'}><img src={tnsuslogo} style={{ marginLeft:"690px"}}/></td>
                                            </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        <br className="clear" /> 
                                    </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Viewjobpost;