import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import Sliderpart from "./Sliderpart";
import Servicespart from "./Servicespart";
import Homeoverview from "./Homeoverview";

function Homepage() {

    return (
        <>
        <Sliderpart />
        <Servicespart />
        <Homeoverview />
        </>
    )

}

export default Homepage