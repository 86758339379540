import React, { useState } from "react";
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Createconsultant = () => {

    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    
    const [formData, setFormData] = useState({
		jobtitle: '',
		fname: '',
        lname: '',
        email: '',
        password: '',
        reconfirmpassword: '',
        tel: '',
        mobile: '',
        city: '',
        state: '',
        address: '',
        workauthorization: '',
        others: '',
        resume:'',
        admid: userdetails.admid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const invalidfeedback1 = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px',
    };

    const tbdisplay = {
        marginTop:'10px',
        display:'none'
    }

    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
    const [inputBox, setInputBox] = useState(false)
    function onChange(value) {
        if(value == "others") {
            setInputBox(true)
            console.log(inputBox)
        } 
    }
    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.fname]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        let fname=e.target.fname.value;
        let lname=e.target.lname.value;
        let jobtitle=e.target.jobtitle.value;
        let email=e.target.email.value;
        let password=e.target.password.value;
        let reconfirmpassword=e.target.reconfirmpassword.value;
        let tel=e.target.tel.value;
        let mobile=e.target.mobile.value;
        let city=e.target.city.value;
        let state=e.target.state.value;
        let address=e.target.address.value;
        let workauthorization=e.target.workauthorization.value;
        let others=e.target.others.value;
        let resume = e.target.resume.files[0];

       
        try {
            const response = await axios.post(`https://api.transnational.ai/api/createconsultant`, {fname:fname,lname:lname,jobtitle:jobtitle,email:email,password:password,reconfirmpassword:reconfirmpassword,
            mobile:mobile,tel:tel,workauthorization:workauthorization,others:others,address:address,city:city,state:state,resume:resume}, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            console.log(response.data);
            
            setErrors({});
            setSuccess(response.data.message);
        } catch (error) {
            //console.log(error.response.status)
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            }else if (error.response && error.response.status === 401) {
                setError(error.response.data.errormessage);
            } else {
                console.error('Error submitting form:', error);
                
            }
        }
    };

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Consultantdashboard">Consultant Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span>Create Consultant</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        { error !== '' && <div className="alert alert-danger">{error}</div> }
                                        <form id="addemployee" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="250"><span className="label_normal">First Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="fname" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.fname && <span style={invalidfeedback}>{errors.fname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Last Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="lname" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.lname && <span style={invalidfeedback}>{errors.lname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Job Title*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="jobtitle" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Email*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="email" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Password*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="password" name="password" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.password && <span style={invalidfeedback}>{errors.password[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Reconfirm Password*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="password" name="reconfirmpassword" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.reconfirmpassword && <span style={invalidfeedback}>{errors.reconfirmpassword[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Mobile*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="mobile" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Alt. Tel# *</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="tel" maxLength={40} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.tel && <span style={invalidfeedback}>{errors.tel[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Work Authorization *</span></td>
											<td width="10">:</td>
											<td width="250"><select size="1" style={{ width: "75%",height:'28px'}} name="workauthorization" id="Consultant_workauthorization" onChange={handleChange}>
                                                            <option value="">-- Work Authorization --</option>
                                                            <option value="CPT">CPT</option>
                                                            <option value="OPT EAD">OPT EAD</option>
                                                            <option value="HIB">HIB</option>
                                                            <option value="L2 EAD">L2 EAD</option>
                                                            <option value="J2 EAD">J2 EAD</option>
                                                            <option value="GC EAD">GC EAD</option>
                                                            <option value="GC">GC</option>
                                                            <option value="US Citizen">US Citizen</option>
                                                            <option value="Others">Others</option>
                                                            </select></td>
                                            <td>{errors.workauthorization && <span style={invalidfeedback}>{errors.workauthorization[0]}</span>}</td>
										</tr>
                                        <tr style={ inputBox ? {marginTop:'10px', display:'block'}  : {marginTop:'10px', display:'none'} }  id="others">
											<td><span className="label_normal"></span></td>
											<td>:</td>
											<td><input type="text" name="others" id="Consultant_others" maxLength={100} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Address*</span></td>
											<td>:</td>
											<td><textarea name="address" type="text" className="ui_text" onChange={handleChange}></textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">City*</span></td>
											<td>:</td>
											<td><input type="text" name="city" maxLength={20} className="ui_text" onChange={handleChange} /></td>
                                            <td>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">State*</span></td>
											<td>:</td>
											<td><select size="1" style={{ width:"75%",height:'28px'}} name="state" onChange={handleChange}>
                                                <option value="">Select State</option>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AZ">AZ</option>
                                                <option value="AR">AR</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="DC">DC</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="IA">IA</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="ME">ME</option>
                                                <option value="MD">MD</option>
                                                <option value="MA">MA</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MS">MS</option>
                                                <option value="MO">MO</option>
                                                <option value="MT">MT</option>
                                                <option value="NE">NE</option>
                                                <option value="NV">NV</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NY">NY</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WV">WV</option>
                                                <option value="WI">WI</option>
                                                <option value="WY">WY</option>
                                                </select></td>
                                            <td>{errors.state && <span style={invalidfeedback}>{errors.state[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Resume*</span></td>
											<td>:</td>
											<td><input type="file" multiple name="resume"  onChange={handleChange}/></td>
                                            <td>{errors.resume && <span style={invalidfeedback}>{errors.resume}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addemployee" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> 
                                        <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        </section>
    );
}

export default Createconsultant;