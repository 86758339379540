import React, { useState,useEffect  } from "react";
import { Link,useParams } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Editcandidate = () => {
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    let {id} = useParams();
    const [lat,setLat]=useState();
    const [candidate, setData] = useState({});
    const [formData, setFormData] = useState({
		name: '',
        feedback: '',
        status: '',
        cvid: '',
        requesttype:''
    });

    useEffect(() => {
        axios.get(`https://api.transnational.ai/api/editcandidate/${id}`)
        .then(response => {
            const retrievedData = response.data.candidate;
            setLat(response.data.lat);
            setData(response.data.candidate);
            setFormData({
                name: retrievedData.name || '',
                feedback: retrievedData.feedback || '',
                status: retrievedData.status || '',
                cvid: retrievedData.cvid || '',
              });
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

        
    

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		let feedback=formData.feedback;
        let status=formData.status;
        let requesttype=e.target.requesttype.value;
        try {

            const response = await axios.post(`https://api.transnational.aiapi/editcandidate/${id}`, {feedback:feedback,status:status,requesttype:requesttype});
            console.log(response.data.name);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/executive/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/executive/Clients">Clients</a> <i class="bi bi-chevron-double-right"></i> <a href={`/executive/Requirements/${lat}`}>Requirements</a> <i class="bi bi-chevron-double-right"></i> <a href={`/executive/Candidates/${candidate.rqid}`}>Candidates</a> <i class="bi bi-chevron-double-right"></i> <span>Edit Candidate  </span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="editprofile" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="150"><span className="label_normal">Name</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="name" maxLength={40} disabled className="ui_text" value={formData.name} onChange={handleChange} /></td>
                                            <td>{errors.name && <span style={invalidfeedback}>{errors.name[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Feedback</span></td>
											<td>:</td>
											<td><input type="text" name="feedback" maxLength={50} className="ui_text" value={formData.feedback} onChange={handleChange} /></td>
                                            <td>{errors.feedback && <span style={invalidfeedback}>{errors.feedback[0]}</span>}</td>
										</tr>
										
										<tr>
											<td><span className="label_normal">Status</span></td>
											<td>:</td>
											<td><select name="status" style={{ height:'30px',width:'220px'}} value={formData.status}  onChange={handleChange}>
                                                <option value="Submitted">Submitted</option>
                                                <option value="Selected">Selected</option>width:'95%'
                                                <option value="Rejected">Rejected</option>
                                                <option value="Hold">Hold</option>
                                            </select></td>
                                            <td><input type="hidden" name="requesttype" value="update" /></td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="editcandidate" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Editcandidate;