import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import services from "../images/banner/services.png";

function Managedservices() {

    const servicesStyle = {
        backgroundImage: `url(${services})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 className="media-heading">Global Managed Services</h3>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational provides services that manage a infrastructure and applications so as to allow customers to concentrate on their core business. Managed service provides for ‘on-going’ operational services as opposed to one-time project based services.</p>
                                      <p style={{ fontSize:'16px',fontFamily:'Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Managed Services – Delivering Value across the value chain</p>
                                      <div>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Help the business reduce overall operation overheads by 15% to 25%</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Transform capital expenditures into operating expenses</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Streamline IT operations and networks, making them more flexible and easily scaled</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Gain expertise that client’s own IT staff may lack</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> 
                                          Leverage existing infrastructures and expertise while developing new revenue</p>
                                      </div>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>In the current economic environment, focusing on core competencies is the key to survival and success. Businesses today face increased network complexity, changing bandwidth needs, and reduced budgets often find that traditional solutions to these challenges distract them from their core business strategies.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Companies are thus increasingly looking at outsourcing services to a managed service provider. Transnational's Managed Services enable customers to outsource business and operations processes and are - assured of the highest standards of quality, repeatability and accountability, in a low risk and cost-effective manner.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Global Managed Services, a specialized group within Transnational, is well equipped to bring the required synergy in various interfaces and interdependencies within solutions, operations and support levels.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Managed Services offers a rich portfolio of services which include application assurance services, managed solutions & remote monitoring and management of IT Infrastructure to the telecommunications sector as an end-to-end managed engagement.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Through this service, we run customer focused long-term programs bringing forth our significant experience, capability, tools and methodologies. These programs envisage various scenarios such as closure, development and feature enhancements etc., which run as a coherent service, and bring in significant benefits to the end customers.</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Managedservices