import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser(){
    const navigate = useNavigate();

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const getUsertype = () =>{
        const usertype = sessionStorage.getItem('usertype');
        const myusertype = JSON.parse(usertype);
        return myusertype;
    }

    const [usertype,setUsertype] = useState(getUsertype());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,usertype) =>{
        sessionStorage.setItem('user',JSON.stringify(user));
        sessionStorage.setItem('usertype',JSON.stringify(usertype));
        setUser(user);
        setUser(usertype);
        navigate(`/${usertype}/Dashboard`);
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }

    const http = axios.create({
        baseURL:"https://api.transnational.ai/api",
        headers:{
			"Access-Control-Allow-Origin": "*",
            "Content-type" : "application/json",
        }
    });

    return {
        setToken:saveToken,
        user,
        usertype,
        getUser,
        getUsertype,
        http,
        logout
    }
}