import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import services from "../images/banner/services.png";

function Consulting() {

    const servicesStyle = {
        backgroundImage: `url(${services})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 class="media-heading">Workforce Solutions</h3>
                                  
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational offers a full range of Workforce Solutions to help analyze your business requirements for effective implementation of software solutions. Within this domain, we provide Workforce Solutions for strategy planning, assessment, procurement, re-engineering solutions, planning, audits, best practices etc.</p>
                                      
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational's Workforce Solutions emphasize their holistic approach in solving clients' business and IT problems, flexibility around the vendor/client relationship, pricing and packaging, the ability to assist in corporate transformation and agility, as well as commitment to creativity and innovation.</p>
                                      
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Our strategic alliances, have given a boost to our market penetration, honed our competitive skills in emerging markets and improved awareness of the latest technologies. Our focus on alliances with multiple product companies helps in Workforce Solutions with our customers on product evaluation as well.</p>
                                      
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Using industry best practices and proven methodologies, our consultants will help you deliver on-time and within budgets.</p>
                                      
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Contact us today for a consultation with one of our Workforce Solutions experts.</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Consulting