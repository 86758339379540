import React, { useState } from 'react';
import axios from 'axios';
import key from "../images/key.png"; 

const invalidfeedback = {
	display: 'block',
	width: '100%',
    fontSize: '12px',
    color : '#dc3545',
	marginLeft : '0px'
};


function Executiveforgotpassword() {

    const [formData, setFormData] = useState({
		email: '',
    });

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState();
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/executiveforgotpassword', formData);
            console.log(response.data.name);

            setFormData({
                email: '',
            });

            setErrors({});
			setSuccess(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };

    return (
        <>
            <section style={{ padding:'100px', background:'#f4f4f4'}}>
                <div className="container" style={{ minHeight:'400px',paddingTop:'50px'}}>
                    <div className="contentbox">
                        <div className="contentheading">Executive Forgot Password</div>
                        <div className="contentbody" align="center">
                            <div style={{ margin:'auto',width:'100%'}}><img src={key} width={80} height={80}/></div>
                            <div className='alert-success'>{success}</div>
                            <form onSubmit={handleSubmit}>
                            <div style={{ minHeight:'220px',padding:'10px',marginTop:'50px'}}>
                                <div><input name="email" style={{ width:'70%'}} maxLength="100" placeholder="Email" value={formData.email} onChange={handleChange} /><br />{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</div>
                                <div style={{ marginTop:'30px'}}><input type="submit" value="SUBMIT" style={{ width:'70%',backgroundColor:'#006dcc',color:'#fff'}} className="btn-send" /></div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Executiveforgotpassword