import React from "react";
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

function Hrdashboard() {

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <span>CT Group Dashboard</span>
                                        </div>
                                    </section>
                            
                                    <div className="container">
                                        <div className="row" style={{ textAlign:'center' }}>
                                            <div className="col-3 profilebox">
                                                <div className="well well-small">
                                                    <a href="/admin/Createexecutive" style={{textDecoration:'none'}}>
                                                    <i style={{ width:'auto',fontSize:'5em',color:'#00a6f4',lineHeight:'1em',height:'auto', marginTop:'15px',textDecoration:'none'}} className="bi bi-person-circle"></i>
                                                    <p>Create Executive</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3 profilebox">
                                                <div className="well well-small">
                                                    <a href="/admin/Executives" style={{textDecoration:'none'}}>
                                                    <i style={{ width:'auto',fontSize:'5em',color:'#d40308',lineHeight:'1em',height:'auto', marginTop:'15px'}} className="bi bi-people-fill"></i>
                                                    <p>View All Executives</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3 profilebox">
                                                <div className="well well-small">
                                                    <a href="/admin/Clients" style={{textDecoration:'none'}}>
                                                    <i style={{ width:'auto',fontSize:'5em',color:'#a1512c',lineHeight:'1em',height:'auto', marginTop:'15px'}} className="bi bi-people-fill"></i>
                                                    <p>View All Clients</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3 ">
                                                
                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        </section>
    );
}

export default Hrdashboard;