import React from "react";
import { BrowserRouter as Router,
    Routes,useNavigate,
    Route,Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';
import tnslogo from '../../images/TNSS-Logo.png';
import AuthUser from '../AuthUser';

import Homepage from '../Homepage';
import Sliderpart from "../Sliderpart";
import Homeoverview from "../Homeoverview";
import Servicespart from "../Servicespart";
import Aboutus from '../Aboutus'
import Vision from '../Vision';
import Global from '../Global';
import Corporatesocial from '../Corporatesocial';
import Solutionintegration from '../Solutionintegration';
import Appdevelopment from '../Appdevelopment';
import Consulting from '../Consulting';
import Managedservices from '../Managedservices';
import Whytransnational from '../Whytransnational';
import Whatweoffer from '../Whatweoffer';
import Lifeculture from '../Lifeculture';
import Termsofuse from '../Termsofuse';
import Privacypolicy from '../Privacypolicy';
import Contactus from '../Contactus';
import Footer from '../Footer';
import Currentopenings from "../Currentopenings";
import Viewjob from "../Viewjob";
import Referrals from "../Referrals";
import Referral from "../Referral";
import Globaladmin from "../Globaladmin";
import Dashboard from "../consultant/Dashboard";
import Profile from "../consultant/Profile";
import Changepassword from "../consultant/Changepassword";
import Editprofile from "../consultant/Editprofile";
import Vendors from "../consultant/Vendors";
import Updatevendor from "../consultant/Updatevendor";
import Addvendor from "../consultant/Addvendor";

function Consultantheader() {

    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    //let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(usertype !== undefined){
            logout();
        }
    }

    return (
        <>
            <section style={{ background: '#f3f2f1' }}>
                <div style={{ height: '40px', width: '100%', background: '#004578'}}>
                    <div className="container">
                    <img className="brand" style={{ padding : '0px', display:'block', position:'absolute', zIndex: '600' }} src={tnslogo} alt=""/>
                        <div style={{ width:'250px', float:'right' }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic"> Welcome {usertype!=='' && userdetails.fname}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                <Dropdown.Item href="/consultant/Dashboard">Dash Board</Dropdown.Item>
                                <Dropdown.Item href="/consultant/Changepassword">Change Password</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={logoutUser}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>                    
                        
                    </div>
                </div>
                <div className="container">
                    <div className="topmenu" style={{marginTop:'0px', marginLeft:'830px'}}>
                        <div className="container">
                            <div id='cssmenu'>
                                <ul>
                                <li className='active has-sub'><a href='#'><span>ABOUT US</span></a></li>
                                <li className='active has-sub'><a href='#'><span>SERVICES</span></a>
                                    <ul>
                                    <li className='has-sub'><a href='/Solutionintegration'><span>Solution Integration</span></a></li>
                                    <li className='has-sub'><a href='/Appdevelopment'><span>App Development & Management</span></a></li>
                                    <li className='has-sub'><a href='/Consulting'><span>Workforce Solutions</span></a></li>
                                    <li className='has-sub'><a href='/Managedservices'><span>Managed Services</span></a></li>
                                    </ul>
                                </li>
                                <li className='active has-sub'><a href='#'><span>CAREERS</span></a>
                                    <ul>
                                    <li className='has-sub'><a href='/Whytransnational'><span>Why Transnational</span></a></li>
                                    <li className='has-sub'><a href='/Whatweoffer'><span>What we offer</span></a></li>
                                    <li className='has-sub'><a href='/Lifeculture'><span>Life & Culture</span></a></li>
                                    <li className='has-sub'><a href='Currentopenings'><span>Current Openings</span></a></li>
                                    <li className='has-sub'><a href='/Referrals'><span>EMPLOYEE REFERRAL PROGRAM</span></a></li>
                                    </ul>
                                </li>
                                <li className='active has-sub'><a href='/Contactus'><span>CONTACT US</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Routes>
                <Route path="/" exact element={<Homepage />} />
                <Route path="/Aboutus" element={<Aboutus />} />
                <Route path="/Vision" element={<Vision />} />
                <Route path="/Global" element={<Global />} />
                <Route path="/Corporatesocial" element={<Corporatesocial />} />
                <Route path="/Solutionintegration" element={<Solutionintegration />} />
                <Route path="/Appdevelopment" element={<Appdevelopment />} />
                <Route path="/Consulting" element={<Consulting />} />
                <Route path="/Managedservices" element={<Managedservices />} />
                <Route path="/Whytransnational" element={<Whytransnational />} />
                <Route path="/Whatweoffer" element={<Whatweoffer />} />
                <Route path="/Lifeculture" element={<Lifeculture />} />
                <Route path="/Termsofuse" element={<Termsofuse />} />
                <Route path="/Privacypolicy" element={<Privacypolicy />} />
                <Route path="/Contactus" element={<Contactus />} />
                <Route path="/Currentopenings" element={<Currentopenings />} />
                <Route path="/Viewjob/:id" element={<Viewjob/>} />
                <Route path="/Referrals" element={<Referrals/>} />
                <Route path="/Referral/:id" element={<Referral/>} />
                <Route path="/Globaladmin" element={<Globaladmin/>} />
                <Route path="/consultant/Dashboard" element={<Dashboard/>} />
                <Route path="/consultant/Profile" element={<Profile/>} />
                <Route path="/consultant/Changepassword" element={<Changepassword/>} />
                <Route path="/consultant/Editprofile" element={<Editprofile/>} />
                <Route path="/consultant/Vendors" element={<Vendors/>} />
                <Route path="/consultant/Updatevendor/:id" element={<Updatevendor/>} />
                <Route path="/consultant/Addvendor" element={<Addvendor/>} />
                <Route path="/consultant/Dashboard" element={<Dashboard/>} />
                <Route path="/consultant/Profile" element={<Profile/>} />
                <Route path="/consultant/Changepassword" element={<Changepassword/>} />
                <Route path="/consultant/Editprofile" element={<Editprofile/>} />
                <Route path="/consultant/Vendors" element={<Vendors/>} />
                <Route path="/consultant/Updatevendor/:id" element={<Updatevendor/>} />
                <Route path="/consultant/Addvendor" element={<Addvendor/>} />
            </Routes>
            <Footer/>
        </>
    );

}

export default Consultantheader