import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import rc from "../../images/banner/rc.png";
import wordicon from "../../images/icon/word_doc.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Consultants(){
    const [records, setRecords] = useState([]);

    useEffect(() => {
        axios.get("https://api.transnational.ai/api/consultants") 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.consultants);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []);

    const handleDownload = (id) => {
        axios({
            url: `https://api.transnational.ai/api/rcdownloadfile/${id}`, 
            method: 'GET',
            responseType: 'blob', 
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error downloading file: ', error);
        });
        
    };

    const servicesStyle = {
        backgroundImage: `url(${rc})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                        <a href="/rc/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> Consultants
                                    </div>
                                </section>
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px'}}>
                                    <h3 className="media-heading"></h3>
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Consultant Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Designation</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {records.map(item => (
                                        <tr  key={item.id}>
                                        <td><Link  to={`/rc/Vendors/${item.cid}`}>{item.fname} {item.lname}</Link></td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.city}</td>
                                        <td>{item.state}</td>
                                        <td>{item.jobtitle}</td>
                                        <td><button onClick={() => handleDownload(item.resume)}><img src={wordicon} width={18} height={18} /></button></td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Consultants