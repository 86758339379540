import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import deleteicon from "../../images/icon/delete.png";
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

export default class Hrreferrals extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            error:null,
            isloaded:false,
            referrals:[],
        }
    };

    componentDidMount() {
        this.fetchData();
    }


    fetchData = async () => {
        let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
       
        try {
            const response = await fetch("https://api.transnational.ai/api/hrreferrals");
            const data = await response.json(); // Assuming you're getting an array of objects with HTML content
            console.log(data.referrals)
			//setSuccess(response.data.message);
            // Map through the data and strip HTML tags for each item
            this.setState({ referrals: data.referrals });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    render(){
        const { referrals } = this.state;
        
        const servicesStyle = {
            backgroundImage: `url(${hr})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor :'#e6f4ff',
            width :'100%',
            height: '33vh',
        };

        const stripHtmlTags = (html) => {
            return html.replace(/<[^>]*>/g, '');
        }; 

        return (
            <section style={{ background :'#f4f4f4' }}>
                <section id="bannerSection" style={servicesStyle}>
                    <div className="container">
                        <h1 id="HR"></h1>
                    </div>
                </section>
                <div className="container" style={{ background :'#ffffff' }}>
                    <div className="col-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                        <a href="/hr/Dashboard">Home</a>  <i class="bi bi-chevron-double-right"></i> <span>EMPLOYEE REFERRAL PROGRAMS</span>
                                        </div>
                                    </section>
                                    
                                    <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"30px"}}>
                                        <h3 className="media-heading"></h3>
                                        
                                        <table className="mytable">
                                        <thead>
                                            <tr>
                                            <th>Introduction</th>
                                            <th width={100}>Posting Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {referrals.map(item => (
                                            <tr  key={item.id}>
                                            <td><Link  to={`/hr/Hrreferral/${item.id}`}>{item.introduction}</Link></td>
                                            <td>{item.postingdate}</td>
                                            </tr>
                                        ))} 
                                        </tbody>
                                        </table>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
        
    }
}
