import React from "react";
import { Link } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import "bootstrap-icons/font/bootstrap-icons.css";

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

function Dashboard() {

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px',padding:'10px' }}>
                <div className="container">
                    <div className="col-md-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section id="breadcrumbs" className="breadcrumb">
                                        
                                    </section>
                                    <div className="container">
                                        <div className="row" style={{ textAlign:'center'}}>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                    <a href="/consultant/profile" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} className="bi bi-person-fill"></i>
                                                    <p >Profile</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/consultant/Editprofile" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#d40308', lineHeight:'1em', height:'auto'}} className="bi bi-pencil-square"></i>
                                                    <p>Edit Profile</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/consultant/Vendors" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-search"></i>
                                                    <p>View All Submissions</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small" >
                                                <a href="/consultant/Addvendor" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} className="bi bi-cart3"></i>
                                                    <p>Add Vendor</p>
                                                </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Dashboard;