import React, { useState, useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import tnsuslogo from "../../images/tnsuslogo.jpg";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Viewjobpost = () => {
    let {id} = useParams();
    const [viewjob,setViewjob]=useState({});
    const [string, setString] = useState();
    
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/viewjobpost/${id}`);
                setViewjob(response.data)
                setString(stripHtmlTags(response.data.description))
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])

    const stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    }; 

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

       
    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/hr/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/hr/Jobposts">Job Posts</a> <i class="bi bi-chevron-double-right"></i><span> {viewjob.title}</span>
                                        </div>
                                    </section>
							        <div className="container" > 
                                   
                                    <div className="content" style={{ border:'none', border:'1px solid #ccc' }}>
                                    <table border={0} cellSpacing={5} cellPadding={7} >
                                    <tbody>
                                    <tr>
                                        <td width={200} valign={'top'}><div ><b>Date Posted </b></div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td width={900}><div className={'content_bold'}>{viewjob.postingdate}</div></td>
                                    </tr>
                                    <tr>
                                        <td valign={'top'}><div><b>Job Title</b></div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td><div className={'content_bold'}>{viewjob.title}</div></td>
                                    </tr>
                                    <tr>
                                        <td valign={'top'}><div ><b>Description </b></div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td valign={'top'}><div >{string}</div></td>
                                    </tr>
                                    <tr>
                                        <td width={200} valign={'top'}><div ><b> Experience</b> </div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td valign={'top'}><div >{viewjob.experience}</div></td>
                                    </tr> 
                                    <tr>
                                        <td valign={'top'}><div ><b>Positions</b> </div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td><div className={'content_bold'}>{viewjob.positions}</div></td>
                                    </tr>
                                    <tr>
                                        <td valign={'top'}><div ><b> Education</b></div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td><div className={'content_bold'}>{viewjob.education}</div></td>
                                    </tr>
                                    <tr>
                                        <td width={200} valign={'top'}><div ><b>Location</b>  </div></td>
                                        <td width={10} valign={'top'}> : </td>
                                        <td><div className={'content_bold'}>{viewjob.city}, {viewjob.state} - {viewjob.zipcode}</div></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td valign={'top'} align={'right'}><Link  to={`/hr/Editjobpost/${viewjob.id}`}><button className="btn btn-primary btn-block">Edit</button></Link></td>
                                    </tr>
                                    </tbody>
                                    </table>
                                    </div>
                                    <div className="container">
                                        <div className='row' style={{ backgroundColor:'#ffffff'}}>
                                            <div className="col-md-11" ></div>
                                            <div className="col-md-1" style={{marginTop:"-15px",  fontFamily:"Arial",fontSize:"12px", fontWeight:"bold", align:"right"}} ></div>
                                        </div>
                                    </div>
                                    <br className="clear" />  
                                    <br />
                                    <br />
                                    </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Viewjobpost;