import React, { useState } from "react";
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Createemployee = () => {

    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    
    const [formData, setFormData] = useState({
		firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        address: '',
        pincode: '',
        country: '',
        jobtitle: '',
        skills: '',
        doj: '',
        dol: '',
        gender: '',
        resume: '',
        admid: userdetails.admid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        
    };
    const handleFileuploadChange =(e) =>{
        const resume = Array.from(e.target.files);
        setSelectedFiles(resume);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        let firstname=e.target.firstname.value;
        let lastname=e.target.lastname.value;
        let jobtitle=e.target.jobtitle.value;
        let gender=e.target.gender.value;
        let email=e.target.email.value;
        let mobile=e.target.mobile.value;
        let skills=e.target.skills.value;
        let address=e.target.address.value;
        let country=e.target.country.value;
        let pincode=e.target.pincode.value;
        let doj=e.target.doj.value;
        let dol=e.target.dol.value;
        let resume = e.target.resume.files;

       
        try {
            const response = await axios.post(`https://api.transnational.ai/api/createemployee`, {firstname:firstname,lastname:lastname,jobtitle:jobtitle,email:email,gender:gender,skills:skills,
            mobile:mobile,address:address,country:country,pincode:pincode,doj:doj,dol:dol,resume:resume}, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            console.log(response.data.name);

            setErrors({});
			setSuccess(response.data.message);
        } catch (error) {
            //console.log(error.response.status)
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            }else if (error.response && error.response.status === 401) {
                setError(error.response.data.errormessage);
            } else {
                console.error('Error submitting form:', error);
                
            }
        }
    };

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Hrdashboard">HR Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span>Create Employee</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        { error !== '' && <div className="alert alert-danger">{error}</div> }
                                        <form id="addemployee" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="250"><span className="label_normal">First Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="firstname" maxLength={40} className="ui_text" value={formData.firstname} onChange={handleChange} /></td>
                                            <td>{errors.firstname && <span style={invalidfeedback}>{errors.firstname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Last Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="lastname" maxLength={40} className="ui_text" value={formData.lastname} onChange={handleChange} /></td>
                                            <td>{errors.lastname && <span style={invalidfeedback}>{errors.lastname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Job Title*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="jobtitle" maxLength={40} className="ui_text" value={formData.jobtitle} onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Skills</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="skills" maxLength={40} className="ui_text" value={formData.skills} onChange={handleChange} /></td>
                                            <td>{errors.skills && <span style={invalidfeedback}>{errors.skills[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Date of Joining*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="date" name="doj" maxLength={40} className="ui_text" value={formData.doj} onChange={handleChange} /></td>
                                            <td>{errors.doj && <span style={invalidfeedback}>{errors.doj[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Date of Leaving</span></td>
											<td width="10">:</td>
											<td width="250"><input type="date" name="dol" maxLength={40} className="ui_text" value={formData.dol} onChange={handleChange} /></td>
                                            <td>{errors.dol && <span style={invalidfeedback}>{errors.dol[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Gender*</span></td>
											<td width="10">:</td>
											<td width="250"><select name="gender" style={{ height:'30px',width:'220px'}} value={formData.gender} onChange={handleChange}>
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select></td>
                                            <td>{errors.gender && <span style={invalidfeedback}>{errors.gender[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Email*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="email" maxLength={40} className="ui_text" value={formData.email} onChange={handleChange} /></td>
                                            <td>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Mobile*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="mobile" maxLength={40} className="ui_text" value={formData.mobile} onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Address*</span></td>
											<td>:</td>
											<td><textarea name="address" type="text" className="ui_text" onChange={handleChange}>{formData.address}</textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Pin Code*</span></td>
											<td>:</td>
											<td><input type="text" name="pincode" maxLength={20} className="ui_text" value={formData.pincode} onChange={handleChange} /></td>
                                            <td>{errors.pincode && <span style={invalidfeedback}>{errors.pincode[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Country*</span></td>
											<td width="10">:</td>
											<td width="250"><select name="country" style={{ height:'30px',width:'220px'}} value={formData.country} onChange={handleChange}>
                                                                <option value="">Select Country</option>
                                                                <option value="usa">United States of America</option>
                                                                <option value="india">India</option>
                                                            </select></td>
                                            <td>{errors.country && <span style={invalidfeedback}>{errors.country[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Documents*</span></td>
											<td>:</td>
											<td><input type="file" multiple name="resume"  onChange={handleFileuploadChange}/></td>
                                            <td>{errors.resume && <span style={invalidfeedback}>{errors.resume}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addemployee" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> 
                                        <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        </section>
    );
}

export default Createemployee;