import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Execclients(){

    const [records, setRecords] = useState([]);
    const id = window.location.href.split('/')[5]
    
    useEffect(() => {
        axios.get(`https://api.transnational.ai/api/execclients/${id}`) 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.execclients);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    const servicesStyle = {
        backgroundImage: `url(${admin})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                        <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Clientdashboard">CT Group Dashboard</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Executives"> Executives</a> <i class="bi bi-chevron-double-right"></i> <span> Clients</span>
                                    </div>
                                </section>
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"30px"}}>
                                    <h3 className="media-heading"></h3>
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Client Name</th>
                                        <th>Contact Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {records.map(item => (
                                        <tr  key={item.id}>
                                        <td>{item.clientname}</td>
                                        <td>{item.contactname}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.created_at}</td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Execclients