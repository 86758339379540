import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import career2 from "../images/banner/career2.png";

function Whatweoffer() {

    const servicesStyle = {
        backgroundImage: `url(${career2})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 className="media-heading">What we offer</h3>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}><bold>Transnational offers</bold> one of the best place for career development. Working with us is more of a mutual growth one that is witnessed by recognition and appreciation. We believe every employee is a value addition to our growth, and the rapid employee growth is a testimony to it. Our people are a happy lot and the organization is a “second home” for all.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational has a strong performance based culture where performance and potential is valued and rewarded.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Fully automated and in-house developed appraisal system is used to drive performance through the organization. It defines expectations from the employee, monitors his/her performance and finally helps in the evaluation.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Employees are given regular feedback by their superiors on their performance to enable them to work efficiently and effectively.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>A constant hand holding and mentoring by the seniors, for the new joiners, help them start delivering in the quickest possible time.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>A well designed growth path and a systematic and well developed job family structure enables employees to choose the career path of his/her choice.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Skills and competency training is a core area of focus for us. The curriculum is well laid out and the effective feedback mechanism helps us tune and customize the competency development programs.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>People care is the heart of the HR team in Transnational. Innovative HR practices, ensures a perfect place for nurturing people and make them grow.</p>	
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Whatweoffer