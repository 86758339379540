import React, { useState,useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Editemployee = () => {
    let {id} = useParams();
    const [employee,setEmployee]=useState({});
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/edithremployee/${id}`);
                setEmployee(response.data.employee)
                console.log(response.data.employee)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])


    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setEmployee({
            ...employee,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		//console.log(rc);
        try {
            const response = await axios.post(`https://api.transnational.ai/api/updatehremployee/${id}`, employee);
            //sessionStorage.setItem('userdetail', JSON.stringify(response.data.employee));
            console.log(response.data.employee);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" style={{ }}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/hr/Dashboard">Home</a>  <i class="bi bi-chevron-double-right"></i> <a href="/hr/Employees">Employees</a> <i class="bi bi-chevron-double-right"></i>  <span>Edit Employee</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addemployee" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="250"><span className="label_normal">First Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="firstname" maxLength={40} className="ui_text" value={employee.firstname} onChange={handleChange} /></td>
                                            <td>{errors.firstname && <span style={invalidfeedback}>{errors.firstname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Last Name*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="lastname" maxLength={40} className="ui_text" value={employee.lastname} onChange={handleChange} /></td>
                                            <td>{errors.lastname && <span style={invalidfeedback}>{errors.lastname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Job Title*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="jobtitle" maxLength={40} className="ui_text" value={employee.jobtitle} onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Skills</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="skills" maxLength={40} className="ui_text" value={employee.skills} onChange={handleChange} /></td>
                                            <td>{errors.skills && <span style={invalidfeedback}>{errors.skills[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Date of Joining*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="date" name="doj" maxLength={40} className="ui_text" value={employee.doj} onChange={handleChange} /></td>
                                            <td>{errors.doj && <span style={invalidfeedback}>{errors.doj[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Date of Leaving</span></td>
											<td width="10">:</td>
											<td width="250"><input type="date" name="dol" maxLength={40} className="ui_text" value={employee.dol} onChange={handleChange} /></td>
                                            <td>{errors.dol && <span style={invalidfeedback}>{errors.dol[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Gender*</span></td>
											<td width="10">:</td>
											<td width="250"><select name="gender" style={{ height:'30px',width:'220px'}} value={employee.gender} onChange={handleChange}>
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select></td>
                                            <td>{errors.gender && <span style={invalidfeedback}>{errors.gender[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Email*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="email" maxLength={40} className="ui_text" value={employee.email} onChange={handleChange} disabled/></td>
                                            <td></td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Mobile*</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="mobile" maxLength={40} className="ui_text" value={employee.mobile} onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Address*</span></td>
											<td>:</td>
											<td><textarea name="address" type="text" className="ui_text" onChange={handleChange} value={employee.address}></textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Pin Code*</span></td>
											<td>:</td>
											<td><input type="text" name="pincode" maxLength={20} className="ui_text" value={employee.pincode} onChange={handleChange} /></td>
                                            <td>{errors.pincode && <span style={invalidfeedback}>{errors.pincode[0]}</span>}</td>
										</tr>
                                        <tr>
											<td width="250"><span className="label_normal">Country*</span></td>
											<td width="10">:</td>
											<td width="250"><select name="country" style={{ height:'30px',width:'220px'}} value={employee.country} onChange={handleChange}>
                                                                <option value="">Select Country</option>
                                                                <option value="usa">United States of America</option>
                                                                <option value="india">India</option>
                                                            </select></td>
                                            <td>{errors.country && <span style={invalidfeedback}>{errors.country[0]}</span>}</td>
										</tr>
                                        <tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addemployee" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> 
                                        <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>   
            
        </section>
        </>
    );
}

export default Editemployee;