import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import services from "../images/banner/services.png";

function Appdevelopment(props) {
  
    const servicesStyle = {
      backgroundImage: `url(${services})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor :'#e6f4ff',
      width :'100%',
      height: '33vh',
    };

    return (
        <>
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div class="container">
                    <h1 id="pageTitle"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
		        <div className="col-12" style={{ margin : '0 auto' }}>
			        <div className="well well-small" style={{ textAlign:"left"}}>
				        <ul className="media-list">
					        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
						        <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                    <h3 class="media-heading">Application Development & Management</h3>
                                
                                    <p style={{ textAlign :'justify',margin : '20px 0'}}>With design and development expertise in diverse platforms, usage of best-of-breed tools and techniques, combined with our state-of-the-art software development facilities, Transnational delivers significant business benefits to its customers.</p>
                                    
                                    <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational offers scalable end-to-end application development and management solutions from requirement analysis to deployment and rollout.</p>
                                    
                                    <p style={{ textAlign :'justify',margin : '20px 0'}}>Our mature yet flexible process framework instills stringent quality measures in our solutions giving customers confidence in our predictable, low-risk, high-quality development and maintenance of services. The present scenario indicates that Indian development centers for packaged business application vendors are taking on increasingly complex responsibilities.</p>
                                    
                                    <p style={{ textAlign :'justify',margin : '20px 0'}}>Likewise, at Transnational we manage mission critical systems for our customers with 0% downtime. A clear testimony of our sound processes, practices, methodologies reflects the fact that 71% of one of our customer’s business is a result of Transnational managing their complex systems.</p>
                                    
                                    <p style={{ textAlign :'justify',margin : '20px 0'}}>Contact us today for a consultation with one of our Application Development and Management experts.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Appdevelopment;