import React, { useState } from 'react';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate
} from "react-router-dom";
import AuthUser from './AuthUser';
import login from "../images/banner/login.png"; 

const invalidfeedback = {
	display: 'block',
	width: '100%',
    fontSize: '12px',
    color : '#dc3545',
	marginLeft : '0px'
};

const invalidfeedback1 = {
	display: 'block',
	width: '100%',
    fontSize: '12px',
    color : '#dc3545',
	marginLeft : '17px'
};

const servicesStyle = {
    backgroundImage: `url(${login})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

function Globaladmin() {

    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const {setToken} = AuthUser();

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState();
        
    const handleChange1 = (event) => {
        setInputValue1(event.target.value);
    };

    const handleChange2 = (event) => {
        setInputValue2(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log();
        try {
            const response = await axios.post('https://api.transnational.ai/api/login', {usertype:selectedOption,email:inputValue1,password:inputValue2});
            console.log(response.data);
            console.log(response.data.usertype);
            setErrors({});
			setSuccess(response.data.message);
            //sessionStorage.setItem('usertype', response.data.usertype);
            //sessionStorage.setItem('userdetail', JSON.stringify(response.data.userdetail));
            console.log(response.data.userdetail)
            setToken(response.data.userdetail,response.data.usertype);

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };

  return (
        <>
            <section style={{ backgroundColor:'#f4f4f4'}}>
                <section id="bannerSection" style={servicesStyle}>
                    <div className="container">
                        <h1 id="pageTitle"></h1>
                    </div>
                </section>
                <div className="container">
                    <div style={{ width:'100%', margin:'2px',backgroundColor:'#0b5bcc' }}>
                        <div style={{ minHeight:'350px', padding:'40px' }}>
                            <form id='login-form' className='login-form' style={{ backgroundColor:'#ffffff',borderRadius:'5px',padding:'50px',margin:'auto',width:'350px'}} onSubmit={handleSubmit}>
                            <select value={selectedOption} name="usertype" style={{ marginLeft:'17px',height:'30px',width:'220px'}}  onChange={handleSelectChange}>
                                <option value="">Select User Type</option>
                                <option value="admin">Admin</option>
                                <option value="hr">HR</option>
                                <option value="rc">Resource Coordinator</option>
                                <option value="consultant">Consultant</option>
                                <option value="executive">Executive Client & Talent</option>
                            </select>
                            {errors.usertype && <span style={invalidfeedback1}>{errors.usertype[0]}</span>}
                            <div style={{ marginTop:'8px',marginLeft :'17px',marginBottom:'2px' }}><input style={{ width:'95%'}} type="text" name="email" maxLength="40" placeholder="E-mail" value={inputValue1} onChange={handleChange1} /><br />{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</div>
                            <div style={{ marginTop:'8px', marginLeft:'17px'}}><input style={{ width:'95%' }} type="password" name="password" maxLength="40" placeholder="Password" value={inputValue2}  onChange={handleChange2} /><br />{errors.password && <span style={invalidfeedback}>{errors.password[0]}</span>}</div>
                            <div style={{ padding: '20px',marginLeft:'17px'}}><button style={{ width:'110%', marginLeft:'-15px'}} className="btn-login btn-primary" type="submit">Login</button></div>
                            {selectedOption && (
                                <div style={{ marginLeft:'20px'}}>
                                    <a href={`/${selectedOption}forgotpassword`}>Forgot Password</a>
                                </div>
                            )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    

  )
}

export default Globaladmin