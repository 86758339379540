import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';



function Homeoverview() {
    return (
        <section id="bodySection" style={{ backgroundColor:'#e9f1f3', padding:'20px'}}>
		<div className="container">
			<div className="row">
				<div className="col-8">
					<div className="videoDescription">
					<h3 align="left">Overview</h3>
						<p id="currentVideoDescription" style={{ textAlign:'justify'}}>Transnational is the global leader in providing end-to-end IT services and solutions . Our professionals serve clients across various Industry's, from offshore development centers across cities in India and America.<br/><br/>Transnational combines deep domain expertise in OSS (Operations Support Systems) and BSS (Business Support Systems) systems, intellectual leadership and a global workforce advantage to provide services to leading players in the telecom, Health care, Banking and also has a product engineering services which integrates local, small and traditional business through local partnerships to maintain a consistent and long term relationships through the use of the product. Transnational provides a wide variety of services ranging from IT strategy and consulting to system integration, design, application development, implementation, maintenance and product engineering.<br/><br/>Committed to quality, Transnational adds value to client businesses through well-established methodologies, tools and techniques backed by its stringent quality processes.
						</p>
					</div>
				</div>
				<div className="col-4" style={{ paddingTop:'35px'}}>
				<h4>Our Product Promotions</h4>
					<section className="mediaBlock clearfix">
						<article className="media clearfix" id="videos">
							<div className="videoFeature clearfix">
								<div className="videoPlayer"><iframe id="player" src="https://www.youtube.com/embed/mhxjVvTD5-Y" allowfullscreen="true" frameborder="0"></iframe>
								</div>
							</div>
							<div className="btn-toolbar" style={{ overflow:'visible'}}>
                            	<div className="btn-group toolTipgroup">
									<a className="btn" href="#" data-placement="right" data-original-title="send email"><i className="bi bi-envelope"></i></a>
									<a className="btn" href="#" data-placement="top" data-original-title="share"><i className="bi bi-share"></i></a>
									<a className="btn" href="https://www.youtube.com/channel/UC7BgHueg1kM_yWtwXA_dsoA/featured" target="_blank" data-placement="left" data-original-title="browse"><i className="bi bi-globe"></i></a>
								</div>
							</div>
						</article>
					</section>
				</div>
			</div>
			<div className="row">
				<div className="col-4">
					<div className="thumbnail">
						<h4>Transnational's Vision</h4>
						<p>
							To be the leading global software solutions provider to the Telecom industry.<br/><br/><br/>
						</p>
					</div>
				</div>
				<div className="col-4">
					<div className="thumbnail">
						<h4>Transnational's Mission Statement</h4>
						<p>
							To be the global leader in Outsourcing Services to the Telecom industry, building on our technologies, competencies and customer interests, and creating value for our customers.<br/>
						</p>
					</div>
				</div>
				<div className="col-4">
					<div className="thumbnail">
						<h4>Our Aim</h4>
						<p>
							To inspire and motivate the maximum number of people around us via effective and proper use of our resources in a rational manner and with humility.<br/><br/>
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
    );
}

export default Homeoverview;