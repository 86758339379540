import React, { useState } from "react";
import { Link } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import word from "../../images/icon/word_doc.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const invalidfeedback = {
    display: 'block',
    width: '100%',
    fontSize: '12px',
    color : '#dc3545',
    marginLeft : '0px'
};

const Editprofile = () => {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    
    const [formData, setFormData] = useState({
		fname: userdetails.fname,
        lname: userdetails.lname,
        jobtitle: userdetails.jobtitle,
        mobile: userdetails.mobile,
        tel: userdetails.tel,
        workauthorization: userdetails.workauthorization,
        address: userdetails.address,
        city: userdetails.city,
        state: userdetails.state,
        cid: userdetails.cid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/consultanteditprofile', formData,);
            console.log(response.data.fname);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/consultant/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <span>Edit Profile</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="editprofile" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="150"><span className="label_normal">First Name</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="fname" maxLength={40} className="ui_text" value={formData.fname} onChange={handleChange} /></td>
                                            <td>{errors.fname && <span style={invalidfeedback}>{errors.fname[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Last Name</span></td>
											<td>:</td>
											<td><input type="text" name="lname" maxLength={50} className="ui_text" value={formData.lname} onChange={handleChange} /></td>
                                            <td>{errors.lname && <span style={invalidfeedback}>{errors.lname[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Job Title</span></td>
											<td>:</td>
											<td><input type="text" name="jobtitle" maxLength={50} className="ui_text" value={formData.jobtitle} onChange={handleChange} /></td>
                                            <td>{errors.jobtitle && <span style={invalidfeedback}>{errors.jobtitle[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Mobile</span></td>
											<td>:</td>
											<td><input type="text" name="mobile" maxLength={20} className="ui_text" value={formData.mobile} onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Alt. Tel#</span></td>
											<td>:</td>
											<td><input type="text" name="tel" maxLength={20} className="ui_text" value={formData.tel} onChange={handleChange} /></td>
                                            <td>{errors.tel && <span style={invalidfeedback}>{errors.tel[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Work Authorization</span></td>
											<td>:</td>
											<td><select size="1" value={formData.workauthorization} style={{ width: "95%",height:'28px'}} name="workauthorization" id="Consultant_workauthorization" onChange={handleChange}>
                                                <option value="">-- Work Authorization --</option>
                                                <option value="CPT">CPT</option>
                                                <option value="OPT EAD">OPT EAD</option>
                                                <option value="HIB">HIB</option>
                                                <option value="L2 EAD">L2 EAD</option>
                                                <option value="J2 EAD">J2 EAD</option>
                                                <option value="GC EAD">GC EAD</option>
                                                <option value="GC">GC</option>
                                                <option value="US Citizen">US Citizen</option>
                                                <option value="Others">Others</option>
                                                </select>
                                            </td>
                                            <td>{errors.workauthorization && <span style={invalidfeedback}>{errors.workauthorization[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Address</span></td>
											<td>:</td>
											<td><textarea name="address" style={{ width:"95%" }} rows={5}  placeholder="Address" onChange={handleChange}>{formData.address}</textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">City</span></td>
											<td>:</td>
											<td><input type="text" name="city" maxLength={20} className="ui_text" value={formData.city} onChange={handleChange} /></td>
                                            <td>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">State</span></td>
											<td>:</td>
											<td><select size="1" style={{ width:"95%",height:'28px'}} value={formData.state} name="state" onChange={handleChange}>
                                                <option value="">Select State</option>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AZ">AZ</option>
                                                <option value="AR">AR</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="DC">DC</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="IA">IA</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="ME">ME</option>
                                                <option value="MD">MD</option>
                                                <option value="MA">MA</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MS">MS</option>
                                                <option value="MO">MO</option>
                                                <option value="MT">MT</option>
                                                <option value="NE">NE</option>
                                                <option value="NV">NV</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NY">NY</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WV">WV</option>
                                                <option value="WI">WI</option>
                                                <option value="WY">WY</option>
                                                </select></td>
                                            <td>{errors.state && <span style={invalidfeedback}>{errors.state[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Existing Resume</span></td>
											<td>:</td>
											<td><img src={word} width={18} height={18}/></td>
                                            <td></td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Resume</span></td>
											<td>:</td>
											<td><input type="file" name="resume" style={{ borderRadius:'5px', width:'100%'}} onChange={handleChange}/></td>
                                            <td>{errors.resume && <span style={invalidfeedback}>{errors.resume[0]}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="editprofile" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Editprofile;