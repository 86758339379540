import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import career4 from "../images/banner/career4.png";

export default class Currentopenings extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            error:null,
            isloaded:false,
            currentopenings:[]
        }
    };
    

    componentDidMount(){
        fetch("https://api.transnational.ai/api/currentopenings")
        .then(res =>res.json())
        .then(
            (result)=>{
                this.setState({
                    isloaded:true,
                    currentopenings:result
                });
            },
            (error)=>{
                this.setState({
                    isloaded:true,
                    error
                });
            }
        )
    }

    render(){
        const { error,isloaded,currentopenings} = this.state;
        if(error){
            return <div>Error: (error.message)</div>
        }else if(!isloaded){
            return <div>Loading...</div>;
        }else{

            const servicesStyle = {
                backgroundImage: `url(${career4})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundColor :'#e6f4ff',
                width :'100%',
                height: '33vh',
            };

            return (
                <section style={{ background :'#f4f4f4' }}>
                    <section id="bannerSection" style={servicesStyle}>
                        <div className="container">
                            <h1 id="pageTitle"></h1>
                        </div>
                    </section>
                    <div className="container" style={{ backgroundColor:'#ffffff'}}>
                        <div className="col-12" style={{ margin:'0 auto'}}>
                            <div className="well well-small" style={{ textAlign:"left" }}>
                                <ul className="media-list">
                                    <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                        <div className="media-body" style={{ minHeight : '500px' , padding : '40px'}}>
                                            <h3 className="media-heading">Current Openings</h3>
                                            <table  className="mytable">
                                            <thead>
                                                <tr >
                                                <th>Job Title</th>
                                                <th>Location</th>
                                                <th>Posted date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {currentopenings.map(item => (
                                                <tr  key={item.id} style={{ paddingLeft:'5px'}}>
                                                <td><Link to={`/Viewjob/${item.id}`} >{item.title}</Link></td>
                                                <td>{item.state},{item.country}</td>
                                                <td>{item.postingdate}</td>
                                                </tr>
                                             ))}   
                                            </tbody>
                                            </table>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}
