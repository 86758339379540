import React, { useState, useEffect, Component } from 'react';
import { Link, useParams} from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import edit from "../../images/icon/edit.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Candidates(){
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [lat, setLat] = useState('');
    let id = window.location.href.split('/')[5]

    useEffect(() => {
        axios.get(`https://api.transnational.ai/api/candidates/${id}`) 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.candidates);
                setLat(response.data.lat);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    const handleFilterchange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRecords = records.filter((record) =>
        record.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const servicesStyle = {
        backgroundImage: `url(${consultant})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                        <a href="/executive/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/executive/Clients">Clients</a> <i class="bi bi-chevron-double-right"></i> <a href={`/executive/Requirements/${lat}`}>Requirements</a> <i class="bi bi-chevron-double-right"></i> <span>Candidates</span>
                                    </div>
                                </section>
                                <div className="container">
                                    <div className='row'>
                                        <div className="col-sm-9 col-md-9 col-lg-9 pull-left"></div>
                                        <div className="col-sm-3 col-md-3 col-lg-3" style={{ marginLeft:'-20px'}}>
                                            <form >
                                            <input style={{ height:"35px",marginRight:"-5px",width:"255px"}} value={searchTerm} onChange={handleFilterchange} type='text' name='keyword' maxLength={150} placeholder='search name' />
                                            <button class="btn btn-warning" style={{ marginTop:"-5px",backgroundColor:"#ccc",border:"#ccc"}} type="button"><i class="bi bi-search" style={{ fontSize:"10px"}}></i></button>
                                            </form>
                                        </div>
                                    </div> 
                                </div>
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px', marginTop:"-10px"}}>
                                    <h3 className="media-heading"></h3>
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Name</th>
                                        <th>Feedback</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {filteredRecords.map(item => (
                                        <tr  key={item.id}>
                                        <td>{item.name}</td>
                                        <td>{item.feedback}</td>
                                        <td>{item.status}</td>
                                        <td><Link  to={`/executive/Editcandidate/${item.cvid}`}><img src={edit} width={15} height={15} /></Link></td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Candidates