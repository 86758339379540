import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import deleteicon from "../../images/icon/delete.png";
import editicon from "../../images/icon/edit.png";
import enableicon from "../../images/icon/enable.gif"; 
import disableicon from "../../images/icon/disable.gif";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

function Hvendors(){
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get("https://api.transnational.ai/api/hvendors") 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.hvendors);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    const handleFilterchange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRecords = records.filter((record) =>
        record.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDelete = async (id) => {
        try {
          axios.get(`https://api.transnational.ai/api/deletehvendor/${id}`);
          setRecords(records.filter(record => record.id !== id));
        } catch (error) {
          console.error('Error deleting record:', error);
        }
    };

    const servicesStyle = {
        backgroundImage: `url(${admin})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="container" style={{ background :'#ffffff' }}>
                <div className="col-12" style={{ margin:'0 auto'}}>
                    <div className="well well-small" style={{ textAlign:"left" }}>
                        <ul className="media-list">
                            <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                    <div  className="col-md-12 breadcrumbs" >
                                        <a href="/admin/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/admin/Hotlistdashboard">Hotlist Dashboard</a> <i class="bi bi-chevron-double-right"></i> <span> Hotlist Vendors</span>
                                    </div>
                                </section>
                                <div className="container">
                                    <div className='row'>
                                        <div className="col-sm-9 col-md-9 col-lg-9 pull-left"></div>
                                        <div className="col-sm-3 col-md-3 col-lg-3" style={{ marginLeft:'-20px'}}>
                                            <form >
                                            <input style={{ height:"35px",marginRight:"-5px",width:"255px"}} value={searchTerm} onChange={handleFilterchange} type='text' name='keyword' maxLength={150} placeholder='search email' />
                                            <button class="btn btn-warning" style={{ marginTop:"-5px",backgroundColor:"#ccc",border:"#ccc"}} type="button"><i class="bi bi-search" style={{ fontSize:"10px"}}></i></button>
                                            </form>
                                        </div>
                                    </div> 
                                </div>
                                <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"-10px"}}>
                                    <h3 className="media-heading"></h3>
                                    
                                    <table className="mytable">
                                    <thead>
                                        <tr>
                                        <th>Company Name</th>
                                        <th>Contact Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {filteredRecords.map(item => (
                                        <tr  key={item.id}>
                                        <td>{item.companyname}</td>
                                        <td>{item.contactname}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td><Link to={`/admin/Edithvendor/${item.id}`} ><img src={editicon} width={22} height={22} style={{marginRight:'10px', marginLeft:'10px'}} /></Link><button onClick={() => handleDelete(item.id)}><img src={deleteicon} /></button></td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Hvendors