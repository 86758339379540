import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import aboutus from "../images/banner/aboutus.png";
import Servicespart from "./Servicespart";

function Global() {

    const aboutusStyle = {
        backgroundImage: `url(${aboutus})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={aboutusStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div class="container" style={{ background :'#ffffff' }}>
                  <div class="col-12" style={{ margin : '0 auto' }}>
                      <div class="well well-small" style={{ textAlign:"left"}}>
                          <ul class="media-list">
                              <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                  <div class="media-body">
                                      <h3 class="media-heading" style={{ padding : '5px 50px'}}>About Company / Global Presence</h3>
                                      <p style={{ padding : '5px 50px', textAlign:"justify"}}>Since 2007, Transnational has grown with 2 development centers and, 2 regional offices across 2 continents. We are globally located and globally integrated, to deliver world-class solutions for your company and your customers.</p>
                                  </div>
                              </li>
                              <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div class="media-body">
                                      <h3 class="media-heading" style={{ padding : '5px 50px'}}>Office Locations & Addresses</h3>
                                      <p style={{ padding : '5px 50px'}}>Asia - Hyderabad</p>
                                      <p style={{ padding : '5px 50px'}}>Florida - Texas</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          <Servicespart />
          </>
      );
}

export default Global