import React, { useState,useEffect,useRef  } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import tnsuslogo from "../images/tnsuslogo.jpg";
import print from "../images/print.png";



const Viewjob = () => {
    let {id} = useParams();
    const [referral,setReferral]=useState({});
    const [introduction, setIntroduction] = useState();
    const [eligibility, setEligibility] = useState();
    const [incentives, setIncentives] = useState();
    const [string, setString] = useState();

    const Print = () =>{     
        let printContents = document.getElementById('printme').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents; 
    }
        
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://127.0.0.1:8000/api/referral/${id}`);
                setReferral(response.data)
                setIntroduction(stripHtmlTags(response.data.introduction))
                setEligibility(stripHtmlTags(response.data.eligibility))
                setIncentives(stripHtmlTags(response.data.incentives))
                setString(stripHtmlTags(response.data.referral))
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])

    const stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    };  

         
    return (
        
        <div className="container" id='printme' style={{ marginTop:'100px', cellSpacing:'5',cellPadding:'7'}}> 
            <br />
            <div className={'jobdet'}><b>EMPLOYEE REFERRAL PROGRAM</b></div>
            <hr  color="#FF6600" size="0.5" />
            <div className="content" style={{ border:'none', border:'1px solid #ccc' }}>
                <table width={940} border={0} cellSpacing={5} cellPadding={7} style={{ marginTop :'20px',width:'100%'}} >
                <tbody>
                <tr>
                    <td width={150} valign={'top'}><div ><b>Date Posted </b></div></td>
                    <td width={10} valign={'top'}> : </td>
                    <td width={756}><div className={'content_bold'}>{referral.postingdate}</div></td>
                </tr>
                <tr>
                    <td valign={'top'}><div><b></b></div></td>
                    <td width={10} valign={'top'}></td>
                    <td><div className={'content_bold'}>{introduction}</div></td>
                </tr>
                <tr>
                    <td valign={'top'}><div ><b>Eligibility</b></div></td>
                    <td width={10} valign={'top'}> : </td>
                    <td valign={'top'}><div >{eligibility}</div></td>
                </tr>
                <tr>
                    <td width={150} valign={'top'}><div ><b>Incentives Offered</b>  </div></td>
                    <td width={10} valign={'top'}> : </td>
                    <td><div className={'content_bold'}>{incentives}</div></td>
                </tr>
                <tr>
                    <td width={150} valign={'top'}><div ><b> Referral Procedure</b> </div></td>
                    <td width={10} valign={'top'}> : </td>
                    <td><div className={'content_bold'}>{string}</div></td>
                </tr> 
                <tr>
                    <td></td>
                    <td></td>
                    <td valign={'top'} align={'right'}><img src={tnsuslogo} style={{ marginLeft:"690px"}}/></td>
                </tr>
                </tbody>
                </table>
            </div>
            <div className="container">
            <div className='row' >
                <div className="col-md-11" style={{ marginTop:"5px",  fontFamily:"Arial",fontSize:"12px", fontWeight:"bold", align:"left"}}><strong>Note</strong> :  Please mail your resumes to : 8951 Cypress Waters Blvd, Ste #160, Coppell, TX – 75019
            Tel: 575-680-2535 | Fax: 786-664-6508.</div>
                <div className="col-md-1 tns1" style={{marginTop:"-15px",  fontFamily:"Arial",fontSize:"12px", fontWeight:"bold", align:"right"}} ><button onClick={Print} style={{ textDecoration:'none', border:'#ffffff'}}><img src={print} style={{ width:'28', height:'28', marginTop:'20px'}}  draggable="false" title="Print" /></button></div>
            </div>
            </div>
            <br className="clear" />  
            <br />
            <br />
        </div>
    );
};

export default Viewjob;
