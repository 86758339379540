import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import services from "../images/banner/services.png";

function Solutionintegration() {

    const servicesStyle = {
        backgroundImage: `url(${services})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'500px', padding : '40px'}}>
                                      <h3 className="media-heading">Solution Integration (SI)</h3>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Solution Integration (SI) services enable clients to identify, develop, and implement the most appropriate solutions which are equipped to meet their change requirements. Growth in the SI services market is fueled by the need for seamless business processes across a company's entire value chain of customers, partners, suppliers, and employees.</p>
                                      <p style={{ fontSize:'16px',fontFamily:'Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Solution Integration is a critical area for companies due to various factors:</p>
                                      <div>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> Multiple systems for various functions that need integration</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> Post mergers & acquisitions, systems & networks need integration</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> New product implementations require integration with the other existing systems</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> Need to maximize existing investments while delivering new solutions and increased business value</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> Integrating with business partners, suppliers & customers to decrease inventory</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4'}}></i> The need to reduce manual interactions</p>
                                      </div>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Transnational offers a wide range of integration services to help you solve your business problems in a manner unique to your business. We at Transnational are committed to delivering fully-integrated solutions that meet your business and technical requirements. As a Solution Integrator, Transnational provides total project management, right from architecture design, integration, system and interface development to migration.</p>
                                      <p style={{ textAlign :'justify',margin : '20px 0'}}>Our ability to use reusable components that enable faster project execution and improved product understanding differentiates our SI services from our competitors. Contact us today for a consultation with one of our SI experts.</p>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          </>
      );

}

export default Solutionintegration