import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import termsofuse from "../images/banner/termsofuse.png";

function Termsofuse() {

    const servicesStyle = {
        backgroundImage: `url(${termsofuse})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
        
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={servicesStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body" style={{ minHeight:'1000px', padding : '40px'}}>
                                      <p style={{ fontSize:'16px',fontFamily:'font-family:Arial, Helvetica, sans-serif',fontWeight:'600',color:'#2954a5'}}>Terms of use</p>
                                      <p style={{ textAlign:"justifyst"}}>The term "User" shall mean the User who is browsing the website www.tnsservices.com (Site).</p>
                                      
                                      <p style={{ textAlign:"justify"}}>The domain address www.tnsservices.com is owned by Transnational Software Services (P) Ltd. All content present on this Site, including all the software, text, images, graphics, video and audio used on this Site, is exclusive and proprietary material owned by Transnational.</p>
                                      
                                      <p style={{ textAlign:"justify"}}>The Transnational name and logo are trademarks which belong to and are owned by Transnational Software Services (P) Ltd. These trademarks may not be used in any manner whatsoever without prior written consent from Transnational Software Services (P) Ltd.</p>
                                      
                                      <p style={{ textAlign:"justify"}}>By using the Site, the User agrees to follow and be bound by the following terms and conditions and these terms and conditions may be revised or modified at any time by Transnational without any notice to the User:</p>
  
                                      <div style={{ paddingLeft:'20px', float :'left'}}>
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>No material from this site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without prior written permission from Transnational . All rights not expressly granted herein are reserved.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Unauthorized use of the materials appearing on this Site may violate copyright, trademark and other applicable laws, and could result in criminal or civil penalties. Transnational may monitor access to the Site.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational may terminate the User's access to the Site at any time for any reason. The provisions regarding disclaimer of warranty, accuracy of information, and indemnification shall survive such termination.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational makes no warranties, express or implied, including, without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an "as is" and "as available" basis.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational shall have no responsibility for any damage to User's computer system or loss of data that results from the download of any content, materials, information from the Site.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational may unilaterally change or discontinue any aspect of the Site at any time, including, its content or features.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational will not be liable for damages of any kind, including without limitation, direct, indirect, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use the Site, or any information provided on the Site, or for any other reason whatsoever.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>The User agrees to indemnify, defend and hold Transnational harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>The User agrees to indemnify, defend and hold Transnational harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>The Site provides links to web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. The User agrees that Transnational is not responsible for the availability of, and content provided on, third party web sites. The User is requested to peruse the policies posted by other web sites regarding privacy and other topics before use. Transnational is not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements, and the User shall bear all risks associated with the use of such content. Transnational is not responsible for any loss or damage of any sort that the User may incur from dealing with any third party.</p>
                                          
                                          <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ color:'#4285f4',float :'left',marginTop:'5px'}}></i>
                                          <p style={{ padding : '0 25px'}}>Transnational makes no warranty that: (a) the Site will meet your requirements; (b) the Site will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable.</p>
                                      </div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          </section>
          </>
      );

}

export default Termsofuse