import React from "react";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import aboutus from "../images/banner/aboutus.png";
import Servicespart from "./Servicespart";

function Corporatesocial() {

    const aboutusStyle = {
        backgroundImage: `url(${aboutus})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
      };
  
      return (
          <>
          <section style={{ background :'#f4f4f4' }}>
              <section id="bannerSection" style={aboutusStyle}>
                  <div class="container">
                      <h1 id="pageTitle"></h1>
                  </div>
              </section>
              <div className="container" style={{ background :'#ffffff' }}>
                  <div className="col-12" style={{ margin : '0 auto' }}>
                      <div className="well well-small" style={{ textAlign:"left"}}>
                          <ul className="media-list">
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding : '5px 50px'}}>About Company / Corporate Social Responsibility</h3>
                                      <p style={{ padding : '5px 50px', textAlign:"justify"}}>At Transnational, community work is not just an act of favour or charity directed towards doing something for the welfare of the needy or poor from our society, it is in fact a complete voluntarily act undertaken to improve the quality of life around, us.</p>
                                      <p style={{ padding : '5px 50px', textAlign:"justify"}}>The enlightened self-interest of every Transnational employee and the concern for meeting their obligation to the society is the raison d'être of this philanthropy. As an active corporate member of the society, Transnational is committed to building relationships with local communities and the society as a whole.</p>
                                  </div>
                              </li>
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding : '5px 50px'}}>Our Aim</h3>
                                      <p style={{ padding : '5px 50px'}}>To inspire and motivate the maximum number of people around us via effective and proper use of our resources in a rational manner and with humility.</p>
                                      <p style={{ padding : '5px 50px'}}>Salient Features of Transnationals Community Services program.</p>
                                      <p style={{ padding : '5px 50px'}}>Value added and vision based donations</p>
                                  </div>
                              </li>
                              <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                  <div className="media-body">
                                      <h3 className="media-heading" style={{ padding : '5px 50px'}}>The five golden commandments</h3>
                                      <p style={{ padding : '5px 50px', textAlign:"justify"}}>Transnational is focused on creating sustainable value growth through innovative solutions and unique partnerships. Our values are at the heart of our business reputation and are essential to our continued success. We foster an environment to instill these values in every facet of our organization.</p>
                                      <div style={{ padding : '5px 50px'}}>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Transparency</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Local partnership</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>People participation</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Voluntary work</p>
                                          <p><i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Openness</p>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          <Servicespart />
          </>
      );

}

export default Corporatesocial