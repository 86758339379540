import React from 'react';
import Modal from 'react-bootstrap/Modal';

function Viewdocument(props) {
    
    let filesrc=String(props.filename).split('.').pop() == 'pdf' ? 
                    `https://api.transnational.ai/uploads/adocs/${props.filename}`                    
                    : 
                    `https://view.officeapps.live.com/op/embed.aspx?src=https://api.transnational.ai/uploads/adocs/${props.filename}`;
    
  return (
    <>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">View Document</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                  <iframe src={filesrc} height="780"  width="100%"></iframe>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default Viewdocument;