import React from "react";
import { a } from 'react-router-dom';
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../images/carousel/business_website_templates_1.jpg';
import slide2 from '../images/carousel/business_website_templates_2.jpg';
import slide3 from '../images/carousel/business_website_templates_3.jpg';

function Sliderpart() {

    return (
       <>
        <section style={{ textAlign:'center'}}>
          <Carousel data-bs-theme="dark">
            <Carousel.Item>
              <img className="d-block w-100" src={slide1} alt="First slide"/>
              <Carousel.Caption style={{ backgroundColor:'#bd1621',width:'500px',height:'100px',borderRadius:'8px',marginLeft:'400px' }}>
              <p style={{ color:'#ffffff',fontSize:'20px'}}>Committed to building relationship<br/>with local communities</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={slide2} alt="First slide"/>
              <Carousel.Caption style={{ backgroundColor:'#bd1621',width:'500px',height:'100px',borderRadius:'8px',marginLeft:'400px' }}>
              <p style={{ color:'#ffffff',fontSize:'20px'}}>Creating sustainable value growth through innovative solutions & unique partnership</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={slide3} alt="First slide"/>
              <Carousel.Caption style={{ backgroundColor:'#bd1621',width:'500px',height:'100px',borderRadius:'8px',marginLeft:'400px' }}>
              <p style={{ color:'#ffffff',fontSize:'20px'}}>Reach out to the world with TRANSNATIONAL<br/>for an exciting journey of growth & learning</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>
       </>     
        
    );
}

export default Sliderpart;