import React, { useState,useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Addvendor = () => {
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
        
    const [formData, setFormData] = useState({
        introduction:'',
        eligibility: '',
        incentives: '',
        referral: '',
        note: '',
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/addreferral', formData,);
            console.log(response.data);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ }}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/hr/Dashboard">Home</a>  <i class="bi bi-chevron-double-right"></i> <span>Add Refrral</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addvendor" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width={170}><span className="label_normal">Intoduction</span></td>
											<td width={10}>:</td>
											<td width={200}><textarea name="introduction"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.introduction}</textarea></td>
                                            <td>{errors.introduction && <span style={invalidfeedback}>{errors.introduction[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Eligibility</span></td>
											<td>:</td>
											<td><textarea name="eligibility"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.eligibility}</textarea></td>
                                            <td>{errors.eligibility && <span style={invalidfeedback}>{errors.eligibility[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Incentives</span></td>
											<td>:</td>
											<td><textarea name="incentives"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.incentives}</textarea></td>
                                            <td>{errors.incentives && <span style={invalidfeedback}>{errors.incentives[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Referral Procedure</span></td>
											<td>:</td>
											<td><textarea name="referral"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.referral}</textarea></td>
                                            <td>{errors.referral && <span style={invalidfeedback}>{errors.referral[0]}</span>}</td>
										</tr>
                                        <tr>
											<td><span className="label_normal">Note</span></td>
											<td>:</td>
											<td><textarea name="note"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.note}</textarea></td>
                                            <td>{errors.note && <span style={invalidfeedback}>{errors.note[0]}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addvendor" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Addvendor;