import React, { useState } from "react";
import { Link } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Addclient = () => {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    
    const [formData, setFormData] = useState({
		clientname: '',
        contactname: '',
        email: '',
        mobile: '',
        address: '',
        exid: userdetails.exid,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/addclient', formData,);
            console.log(response.data.name);

            setFormData({
                clientname: '',
                contactname: '',
                email: '',
                mobile: '',
                address: '',
            });

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/executive/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <span>Add Client</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addclient" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="150"><span className="label_normal">Clientname</span></td>
											<td width="10">:</td>
											<td width="250"><input type="text" name="clientname" maxLength={40} className="ui_text" value={formData.clientname} onChange={handleChange} /></td>
                                            <td>{errors.clientname && <span style={invalidfeedback}>{errors.clientname[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">contactname</span></td>
											<td>:</td>
											<td><input type="text" name="contactname" maxLength={50} className="ui_text" value={formData.contactname} onChange={handleChange} /></td>
                                            <td>{errors.contactname && <span style={invalidfeedback}>{errors.contactname[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Email</span></td>
											<td>:</td>
											<td><input type="text" name="email" maxLength={100} className="ui_text" value={formData.email} onChange={handleChange} /></td>
                                            <td>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</td>
                                        </tr>
										<tr>
											<td><span className="label_normal">Mobile</span></td>
											<td>:</td>
											<td><input type="text" name="mobile" maxLength={20} className="ui_text" value={formData.mobile} onChange={handleChange} /></td>
                                            <td>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</td>
										</tr>
										<tr>
											<td><span className="label_normal">Address</span></td>
											<td>:</td>
											<td><textarea name="address"  className="ui_text" style={{ height:'100px'}} onChange={handleChange}>{formData.address}</textarea></td>
                                            <td>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="createclient" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Addclient;