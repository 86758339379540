import React from "react";
import { Link } from 'react-router-dom';
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";

const servicesStyle = {
    backgroundImage: `url(${hr})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

function Dashboard() {
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    
    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px',padding:'10px' }}>
                <div className="container">
                    <div className="col-md-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section id="breadcrumbs" className="breadcrumb">
                                        
                                    </section>
                                    <div className="container">
                                        <div className="row" style={{ textAlign:'center'}}>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                    <a href="/hr/Profile" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} className="bi bi-person-fill"></i>
                                                    <p >Profile</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Editprofile" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#d40308', lineHeight:'1em', height:'auto'}} className="bi bi-pencil-square"></i>
                                                    <p>Edit Profile</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small" >
                                                <a href="/hr/Addnewjob" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} className="bi bi-floppy2"></i>
                                                    <p>Add New Job</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Jobposts" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-card-list"></i>
                                                    <p>View All Job Posts</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Createemployee" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-person-plus-fill"></i>
                                                    <p>Create Employee</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Employees" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-people-fill"></i>
                                                    <p>View All Employees</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Addagreement" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-file-earmark"></i>
                                                    <p>Create Document</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Agreements" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-folder-symlink-fill"></i>
                                                    <p>View All Document</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Hrreferrals" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-card-list"></i>
                                                    <p>EMPLOYEE <br></br> REFERRAL PROGRAMS</p>
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 profilebox">
                                                <div className="well well-small">
                                                <a href="/hr/Addreferral" style={{ textDecoration:'none'}}>
                                                    <i style={{ width:'auto', fontSize:'5em',color:'#00a6f4', lineHeight:'1em', height:'auto'}} class="bi bi-person-plus-fill"></i>
                                                    <p>Create EMPLOYEE <br></br>REFERRAL PROGRAM</p>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Dashboard;