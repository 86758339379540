import React from 'react'
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import aboutus from "../images/banner/aboutus.png";
import history from "../images/banner/history.png";

const aboutusStyle = {
    backgroundImage: `url(${aboutus})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

function Aboutus(props) {

    return (
        <section style={{ background :'#f4f4f4' }}>
            <section id="bannerSection" style={aboutusStyle}>
                <div class="container">
                    <h1 id="pageTitle"></h1>
                </div>
            </section>
            <div class="container" style={{ background :'#ffffff' }}>
                <div class="col-md-12" style={{ margin:'0 auto'}}>
                    <div class="well well-small" style={{ textAlign:"left" }}>
                        <ul class="media-list">
                            <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                <div class="media-body" align="center" >
                                    <p style={{ width :'1000px', textAlign : "justify",padding : '5px 50px' }}>Since 2007, Transnational has grown with 2 development centers and, 2 regional offices across 2 continents. We are globally located and globally integrated, to deliver world-class solutions for your company and your customers. </p>
                                    <img src={history} alt=""/>
                                </div>
                            </li>
                            <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                <div class="media-body">
                                    <h3 class="media-heading" style={{ padding : '5px 50px' }}>The Values that drive us</h3>
                                    <p style={{ padding : '5px 50px',textAlign : "justify" }}>Transnational is focused on creating sustainable value growth through innovative solutions and unique partnerships. Our values are at the heart of our business reputation and are essential to our continued success. We foster an environment to instill these values in every facet of our organization.</p>
                                    <div style={{ padding : '5px 50px' }}>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Good corporate citizenship</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Professionalism</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Customer first</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Commitment to quality</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Dignity of the individual</p>
                                    </div>
                                </div>
                            </li>
                            <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px'}}>
                                <div class="media-body">
                                    <h3 class="media-heading" style={{ padding : '5px 50px' }}>Corporate Social Responsibility</h3>
                                    <p style={{ padding : '5px 50px' }}>At Transnational, ‘community work’ is not just an act of favour or charity directed towards doing something for the welfare of the ‘needy or poor’ from our society, it is in fact a complete voluntarily act undertaken to improve the quality of life around, us.<br/><br/> The enlightened self-interest of every Transnational employee and the concern for meeting their obligation to the society is the reason of this philanthropy. As an active corporate member of the society, Transnational is committed to building relationships with local communities and the society as a whole.</p>
                                </div>
                            </li>
                            <li class="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                <div class="media-body">
                                    <h3 class="media-heading" style={{ padding : '5px 50px' }}>The five golden commandments</h3>
                                    <div style={{ padding : '5px 50px' }}>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Transparency</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Local partnership</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>People participation</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Voluntary work</p>
                                        <p><i class="fa fa-arrow-circle-right" aria-hidden="true" style={{ marginRight:'10px',color:'#4285f4' }}></i>Openness</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Aboutus