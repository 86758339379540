import React, { useState } from "react";
import { Link,useParams } from 'react-router-dom';
import consultant from "../../images/banner/consultant.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import AuthUser from '../AuthUser';

const servicesStyle = {
    backgroundImage: `url(${consultant})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Addrequirement = () => {
    const {usertype,logout,getUser} = AuthUser();
    let userdetails = getUser();
    //let id = userdetails.exid
    //let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    let {id} = useParams();
    
    const [formData, setFormData] = useState({
		requirement: '',
        exid: userdetails.exid,
        clid:id,
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		//console.log(formData);
        try {
            const response = await axios.post(`https://api.transnational.ai/api/addrequirement/${id}`, formData,);
            setErrors({});
			setSuccess(response.data.message);
            //console.log(response.data);
        } catch (error) {
            //console.log(error.response.status)
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            }else if (error.response && error.response.status === 401) {
                setError(error.response.data.errormessage);
            } else {
                console.error('Error submitting form:', error);
                
            }
        }
    };


    

    return (
        <>
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ padding:'10px'}}>
		        <div className="container">
			        <div className="col-md-12" style={{ margin:'0 auto'}}>
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff'}} >
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/executive/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/executive/Clients">Clients</a> <i class="bi bi-chevron-double-right"></i> <span>Add Requirement</span>
                                        </div>
                                    </section>
							        <div className="container" style={{ minHeight:'400px'}}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }
                                        <form id="addrequirement" onSubmit={handleSubmit}>
									    <table width={940} border={0} align="center" cellPadding={10} cellSpacing={10}>
										<tr>
											<td width="250"><span className="label_normal">Requirement</span></td>
											<td width="10">:</td>
											<td width="250"><textarea name="requirement" className="ui_text" onChange={handleChange}>{formData.requirement}</textarea></td>
                                            <td>{errors.requirement && <span style={invalidfeedback}>{errors.requirement[0]}</span>}</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td></td>
											<td height="50"><input name="addrequirement" type="submit" value="Submit" className="btn btn-primary btn-block" style={{ width:'220px'}} /></td>
                                            <td></td>
										</tr>
									    </table>
								        </form> <br/>
							        </div>
                                </li>
                            </ul>
                        </div>
				    </div>
                </div>
            </div>
            
        </section>
        </>
    );
}

export default Addrequirement;