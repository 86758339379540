import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import rc from "../../images/banner/rc.png";
import "bootstrap-icons/font/bootstrap-icons.css";

export default class Vendors extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            error:null,
            isloaded:false,
            vendors:[],
            consultant:[],
            search:'',
            filtervendors:[]
        }
        this.handleFilterchange=this.handleFilterchange.bind(this);
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
        const id = window.location.href.split('/')[5]
        console.log(id)
        try {
            const response = await fetch(`https://api.transnational.ai/api/vendors/${id}`);
            const data = await response.json(); // Assuming you're getting an array of objects with HTML content
            console.log(data.vendors)
            // Map through the data and strip HTML tags for each item
            this.setState({ vendors: data.vendors,filtervendors: data.vendors,consultant:data.consultant });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    handleFilterchange = (e) => {
        const value = e.target.value;
        console.log(value)
        this.setState({search:value})
        const filtered = this.state.vendors.filter(item=> item.vendor.includes(value))
        this.setState({filtervendors: filtered})
    }

    stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    };
    

    

    render(){
        const { filtervendors } = this.state;
        
        const servicesStyle = {
            backgroundImage: `url(${rc})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor :'#e6f4ff',
            width :'100%',
            height: '33vh',
        };

        return (
            <section style={{ background :'#f4f4f4' }}>
                <section id="bannerSection" style={servicesStyle}>
                    <div className="container">
                        <h1 id="HR"></h1>
                    </div>
                </section>
                <div className="container" style={{ background :'#ffffff' }}>
                    <div className="col-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                            <a href="/rc/Dashboard">Home</a> <i class="bi bi-chevron-double-right"></i> <a href="/rc/Consultants">Consultants</a> <i class="bi bi-chevron-double-right"></i> <span> {this.state.consultant.fname} {this.state.consultant.lname}'s Vendors</span>
                                        </div>
                                    </section>
                                    <div className="container">
                                        <div className='row'>
                                            <div className="col-sm-9 col-md-9 col-lg-9 pull-left"></div>
                                            <div className="col-sm-3 col-md-3 col-lg-3" style={{ marginLeft:'-20px'}}>
                                                <form >
                                                <input style={{ height:"35px",marginRight:"-5px",width:"255px"}} value={this.state.search} onChange={this.handleFilterchange} type='text' name='keyword' maxLength={150} placeholder='search vendor' />
                                                <button class="btn btn-warning" style={{ marginTop:"-5px",backgroundColor:"#ccc",border:"#ccc"}} type="button"><i class="bi bi-search" style={{ fontSize:"10px"}}></i></button>
                                                </form>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"-10px"}}>
                                        <h3 className="media-heading"></h3>
                                        <table className="mytable">
                                        <thead>
                                            <tr>
                                            <th>Vendor Name</th>
                                            <th>Contact Name</th>
                                            <th>Client Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Date & Time</th>
                                            <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {filtervendors.map(item => (
                                            <tr  key={item.id}>
                                            <td><Link  to={`/rc/Updatevendor/${item.id}`}>{item.vendor}</Link></td>
                                            <td>{item.contactperson}</td>
                                            <td>{item.clientname}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.statusdate}</td>
                                            <td>{item.status}</td>
                                            </tr>
                                        ))} 
                                        </tbody>
                                        </table>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
        
    }
}
