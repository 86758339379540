import React, { useState } from "react";
import login from "../images/login.jpg";
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import Button from 'react-bootstrap/Button';

const servicesStyle = {
    backgroundImage: `url(${login})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width :'100%',
    marginTop:'15px',
    backgroundSize:'100%',
    padding:'50px',
};

const Consultantregister = () => {
        
    const [formData, setFormData] = useState({
        jobtitle: '',
		fname: '',
        lname: '',
        email: '',
        password: '',
        reconfirmpassword: '',
        tel: '',
        mobile: '',
        city: '',
        state: '',
        address: '',
        workauthorization: '',
        others: '',
        resume:'',
    });

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '30px',
    };

    const invalidfeedback1 = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px',
    };
    const tbdisplay = {
        marginTop:'10px',
        display:'none'
    }

    
    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const [inputBox, setInputBox] = useState(false)
    function onChange(value) {
        if(value == "others") {
            setInputBox(true)
            console.log(inputBox)
        } 
    }
    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.fname]: e.target.value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        let fname=e.target.fname.value;
        let lname=e.target.lname.value;
        let jobtitle=e.target.jobtitle.value;
        let email=e.target.email.value;
        let password=e.target.password.value;
        let reconfirmpassword=e.target.reconfirmpassword.value;
        let tel=e.target.tel.value;
        let mobile=e.target.mobile.value;
        let city=e.target.city.value;
        let state=e.target.state.value;
        let address=e.target.address.value;
        let workauthorization=e.target.workauthorization.value;
        let others=e.target.others.value;
        let resume = e.target.resume.files[0];
        try {
            const response = await axios.post(`https://api.transnational.ai/api/consultantregister`, {fname:fname,lname:lname,jobtitle:jobtitle,email:email,password:password,reconfirmpassword:reconfirmpassword,
            tel:tel,mobile:mobile,city:city,state:state,address:address,workauthorization:workauthorization,others:others,resume:resume}, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            console.log(response.data.fname);

            

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };


    

    return (
        <>
            <section style={servicesStyle} >
                <div className="concontentbox" style={{ marginTop:"0px"}}>
		            <div className="concontentheading">Consultant Registration</div>
                    <div className="concontentbody">
                        <form onSubmit={handleSubmit}>
                            <div className="col-md-12">
                                { success !== '' && <div className="alert alert-success">{success}</div> }
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <input type="text" name="fname" placeholder="First Name" style={{ width:"90%",marginLeft:'30px'}} maxLength={40} onChange={handleChange}/>
                                        <div>{errors.fname && <span style={invalidfeedback}>{errors.fname[0]}</span>}</div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="lname" placeholder="Last Name" style={{ width:"90%"}} maxLength={40} onChange={handleChange}/>
                                        <div>{errors.lname && <span style={invalidfeedback1}>{errors.lname[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-6">
                                        <input type="text" name="email" placeholder="Email" style={{ width:"90%",marginLeft:'30px' }} maxLength={100} onChange={handleChange}/>
                                        <div>{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="jobtitle" placeholder="Job Title" style={{ width:"90%" }} maxLength={40} onChange={handleChange}/>
                                        <div>{errors.jobtitle && <span style={invalidfeedback1}>{errors.jobtitle[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-6">
                                        <input type="password" name="password" placeholder="Choose Password" style={{ width:"90%",marginLeft:'30px' }} maxLength={30} onChange={handleChange}/>
                                        <div>{errors.password && <span style={invalidfeedback}>{errors.password[0]}</span>}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="password" name="reconfirmpassword" placeholder="Reconfirm Password" style={{ width:"90%"}} maxLength={30} onChange={handleChange}/>
                                        <div>{errors.reconfirmpassword && <span style={invalidfeedback1}>{errors.reconfirmpassword[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-12">
                                        <select size="1" style={{ width: "91%",marginLeft:'30px',height:'28px'}} name="workauthorization" id="Consultant_workauthorization" onChange={handleChange}>
                                        <option value="">-- Work Authorization --</option>
                                        <option value="CPT">CPT</option>
                                        <option value="OPT EAD">OPT EAD</option>
                                        <option value="HIB">HIB</option>
                                        <option value="L2 EAD">L2 EAD</option>
                                        <option value="J2 EAD">J2 EAD</option>
                                        <option value="GC EAD">GC EAD</option>
                                        <option value="GC">GC</option>
                                        <option value="US Citizen">US Citizen</option>
                                        <option value="Others">Others</option>
                                        </select>                       
                                        <div>{errors.workauthorization && <span style={invalidfeedback}>{errors.workauthorization[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={ inputBox ? {marginTop:'10px', display:'block'}  : {marginTop:'10px',
        display:'none'} }  id="others" >
                                    <input maxLength={100} style={{ width:"88%", marginLeft:'42px' }} name="others" id="Consultant_others" type="text" onChange={handleChange}/>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-6">
                                        <input type="text" name="mobile" placeholder="Mobile" style={{ width:"90%",marginLeft:'30px' }} maxLength={30} onChange={handleChange}/>
                                        <div>{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="tel" placeholder="Alt. Tel#" style={{ width:"90%"}} maxLength={30} onChange={handleChange}/>
                                        <div>{errors.tel && <span style={invalidfeedback1}>{errors.tel[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-12">
                                        <textarea name="address" style={{ width:"91%", marginLeft:'30px'}} rows={5}  placeholder="Address" onChange={handleChange}></textarea>
                                        <div>{errors.address && <span style={invalidfeedback}>{errors.address[0]}</span>}</div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-6">
                                        <input type="text" name="city" placeholder="City" style={{ width:"90%",marginLeft:'30px' }} maxLength={50} onChange={handleChange}/>
                                        <div>{errors.city && <span style={invalidfeedback}>{errors.city[0]}</span>}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <select size="1" style={{ width:"90%",height:'28px'}} name="state" onChange={handleChange}>
                                        <option value="">Select State</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DE">DE</option>
                                        <option value="DC">DC</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="HI">HI</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="IA">IA</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="ME">ME</option>
                                        <option value="MD">MD</option>
                                        <option value="MA">MA</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NV">NV</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NY">NY</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WV">WV</option>
                                        <option value="WI">WI</option>
                                        <option value="WY">WY</option>
                                        </select>
                                        <div>{errors.state && <span style={invalidfeedback1}>{errors.state[0]}</span>}</div>
                                    </div>
                               </div>
                               <div className="row" style={{ marginTop:'10px'}}>
                                    <div className="col-md-12">
                                        <input type="file" name="resume" style={{ borderRadius:'5px', width:'100%',marginLeft:'30px'}} onChange={handleChange}/>
                                        <div>{errors.resume && <span style={invalidfeedback}>{errors.resume[0]}</span>}</div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop:'0px'}}>
                                    <div className="col-md-12" style={{ alignItems:"center"}}>
                                        <Button name="consultant" type="submit" style={{ marginLeft:'300px'}}>CREATE ACCOUNT</Button>
                                    </div>
                                </div>
                            </div> 
                            
                        </form>
                    </div>
		        </div>
            </section>
        </>
    );
    
}

export default Consultantregister;