import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import view from "../../images/icon/view.png";
import download from "../../images/icon/download.png";
import deleteicon from "../../images/icon/delete.png";
import hr from "../../images/banner/hr.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import Viewdocument from './Viewdocument';

const Empagreement = () => {
    const [records, setRecords] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const handleModalShow = (filename) =>{
            setModalshow(true);
            setFile(filename);
        } 
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    const id = window.location.href.split('/')[5]

    const servicesStyle = {
        backgroundImage: `url(${hr})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor :'#e6f4ff',
        width :'100%',
        height: '33vh',
    };

    useEffect(() => {
        axios.get(`https://api.transnational.ai/api/hrempagreement/${id}`) 
            .then(response => {
                console.log(response.data)
                setRecords(response.data.empdocs);
            })
            .catch(error => {
                console.error('Error fetching records:', error);
            });
    }, []); 

    const handleDelete = async (id) => {
        try {
          await axios.get(`https://api.transnational.ai/api/deletehrempdoc/${id}`); 
          setRecords(records.filter(record => record.id !== id));
        } catch (error) {
          console.error('Error deleting record:', error);
        }
      };

    const handleDownload = (id) => {
        axios({
            url: `https://api.transnational.ai/api/hrempdocdownload/${id}`, 
            method: 'GET',
            responseType: 'blob', 
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error downloading file: ', error);
        });
        
    };
   

    return (
        <section style={{ background :'#f4f4f4' }}>
                <section id="bannerSection" style={servicesStyle}>
                    <div className="container">
                        <h1 id="HR"></h1>
                    </div>
                </section>
                <div className="container" style={{ background :'#ffffff' }}>
                    <div className="col-12" style={{ margin:'0 auto'}}>
                        <div className="well well-small" style={{ textAlign:"left" }}>
                            <ul className="media-list">
                                <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-md-12 breadcrumbs" >
                                        <a href="/hr/Dashboard">Home</a> <i className="bi bi-chevron-double-right"></i> <a href="/hr/Agreements">Documents</a> <i className="bi bi-chevron-double-right"></i> <span>View All Documents</span>
                                        </div>
                                    </section>
                                    <div className="container">
                                        <div className='row'>
                                            <div className="col-10"></div>
                                            <div className="col-2 Pull-right" >
                                                <Link  to={`/hr/Adddocument/${id}`}>Add Document</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media-body" style={{ minHeight : '500px' , padding : '40px',marginTop:"-10px"}}>
                                        <h3 className="media-heading"></h3>
                                        <table className="mytable">
                                        <thead>
                                            <tr>
                                            <th>Documents</th>
                                            <th>Created Date</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {records.map(record => (
                                            <tr  key={record.id}>
                                            <td>{record.originaldocs}</td>
                                            <td>{record.created_at}</td>
                                            <td><a href="#" onClick={()=>handleModalShow(record.documents)}><img src={view} width={18} height={18} /></a>&nbsp;<a href='#' onClick={() => handleDownload(record.documents)}><img src={download} width={18} height={18} /></a>&nbsp;&nbsp;<button onClick={() => handleDelete(record.id)}><img src={deleteicon} width={18} height={18} /></button></td>
                                            </tr>
                                        ))} 
                                        </tbody>
                                        </table>
                                        
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Viewdocument show={modalshow} filename={file} close={() => setModalshow(false)} />
            </section>
    );
};

export default Empagreement;