import React, { useState } from 'react';
import axios from 'axios';
import contactus from "../images/banner/contactus.png";


const invalidfeedback = {
	display: 'block',
	width: '100%',
    fontSize: '12px',
    color : '#dc3545',
	marginLeft : '65px'
};

const Contactus = () => {
    const [formData, setFormData] = useState({
		name: '',
		email: '',
		mobile: '',
		description: '',
    });

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState();
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

	const servicesStyle = {
		backgroundImage: `url(${contactus})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor :'#e6f4ff',
		width :'100%',
		height: '33vh',
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.transnational.ai/api/contactus', formData);
            console.log(response.data.name);

            setFormData({
                name: '',
                email: '',
				mobile: '',
                description: '',
            });

            setErrors({});
			setSuccess(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };

	return (
		<>
		<section style={{ background :'#f4f4f4' }}>
			<div className="container2">	
				<section id="bannerSection" style={servicesStyle}>
					<div className="container">
						<h1 id="pageTitle"></h1>
					</div>
				</section>
				<div className="container" style={{ backgroundColor:"#ffffff" }}>
					<div className="scol-12" style={{ margin : '0 auto' }}>
						<div className="well well-small" style={{ textAlign:"left" }} >
							<ul className="media-list">
								<li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
									<div className="media-body" align="center">
										<div className="row" align="left">
											<div className="col-4">
												<p style={{ paddingTop:"50px", marginLeft:'30px'}}>USA :</p>
												<h3 style={{ color:"#2954a5", fontSize:"16px",marginLeft:'30px'}}>Transnational Software Services Inc</h3>
												<p style={{ marginLeft:'30px'}}>8951 Cypress Waters Blvd, Ste #160<br />Coppell, TX - 75019.<br /><b> Tel : 575-680-2535 , Fax : 786-664-6508</b></p>
											</div>
											<div className="col-8" style={{ paddingTop:"50px" }}>
												<div className='alert-success'>{success}</div>
												<form id="contact-form" className="contact-form" onSubmit={handleSubmit}>
													<input name="name" maxLength="40" placeholder="Name" value={formData.name} onChange={handleChange} /><br/>
													{errors.name && <span style={invalidfeedback}>{errors.name[0]}</span>}
													<input name="email" maxLength="100" placeholder="Email" value={formData.email} onChange={handleChange} /><br/>
													{errors.email && <span style={invalidfeedback}>{errors.email[0]}</span>}
													<input name="mobile" maxLength="15" placeholder="Mobile" value={formData.mobile} onChange={handleChange} /><br/>
													{errors.mobile && <span style={invalidfeedback}>{errors.mobile[0]}</span>}
													<textarea name="description" rows="4" cols="16" placeholder="Enter your message for us here. We will get back to you within 2 business days." value={formData.description} onChange={handleChange} /><br/>
													{errors.description && <span style={invalidfeedback}>{errors.description[0]}</span>}
													<button className="btn-send" type="submit">Submit</button>
												</form>
											</div>
										</div>
									</div>
									<div className="col-12" style={{ paddingTop:"50px" }}>
										<iframe style={{ border:"solid 4px #CCCCCC", border:"0" }} width="100%" height="260" frameborder="0" allowfullscreen  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15221.750159376094!2d78.3814577!3d17.4866149!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb918980181e55%3A0x7c346c0d33e67bdb!2sTransnational+Software+Services+PVT+Ltd!5e0!3m2!1sen!2sin!4v1488867124121"></iframe>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		</>
		
	);
};

export default Contactus;