import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import admin from "../../images/banner/admin.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';

const servicesStyle = {
    backgroundImage: `url(${admin})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor :'#e6f4ff',
    width :'100%',
    height: '33vh',
};

const Asigntoconsultant = () => {
    let userdetail = JSON.parse(sessionStorage.getItem('userdetail'));
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);

    const [singleOption, setSingleOption] = useState('');
    const [multipleOptions, setMultipleOptions] = useState([]);

    

    const invalidfeedback = {
        display: 'block',
        width: '100%',
        fontSize: '12px',
        color : '#dc3545',
        marginLeft : '0px'
    };

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState('');
    const handleSingleChange = (event) => {
        setSingleOption(event.target.value);
    };
    
    const handleMultipleChange = (event) => {
        const selected = Array.from(event.target.selectedOptions, (option) => option.value);
        setMultipleOptions(selected);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            singleOption,
            multipleOptions,
        };
		
        try {
            const response = await axios.post('https://api.transnational.ai/api/asigntoconsultant', formData);
            console.log(formData);

            setErrors({});
			setSuccess(response.data.message);
            

        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
    };

    useEffect(() => {
        fetch('https://api.transnational.ai/api/asignrcs')
          .then(response => response.json())
          .then(data => { setOptions1(data.rcs) })
          .catch(error => console.error('Error fetching data:', error));
      }, []); 

      useEffect(() => {
        fetch('https://api.transnational.ai/api/asigncs')
          .then(response => response.json())
          .then(data => { setOptions2(data.cs) })
          .catch(error => console.error('Error fetching data:', error));
      }, []); 
    

    return (
        <section style={{ backgroundColor:'#f4f4f4' }}>
            <section id="bannerSection" style={servicesStyle}>
                <div className="container">
                    <h1 id="HR"></h1>
                </div>
            </section>
            <div className="media-body" style={{ minHeight:'500px', padding:'10px'}}>
		        <div className="container">
			        <div className="col-12" >
				        <div className="well well-small" style={{ textAlign:'left', backgroundColor:'#fff' }}>
					        <ul className="media-list">
						        <li className="media well well-small" style={{ marginTop:'10px',marginLeft:'-15px',marginRight:'15px',marginBottom:'-5px'}}>
                                    <section className="breadcrumb" style={{ marginLeft:'5px', marginTop:'5px',marginRight:'5px'}}>
                                        <div  className="col-12 breadcrumbs" >
                                            <a href="/admin/Dashboard">Home</a>  <i class="bi bi-chevron-double-right"></i> <span>Asign to Consultant</span>
                                        </div>
                                    </section>
                            
                                    <div className="container" style={{ minHeight:'400px'}}>
                                        <form id="addemployee" onSubmit={handleSubmit}>
                                        { success !== '' && <div className="alert alert-success">{success}</div> }    
									    <table width={700} border={0} cellspacing={0} cellpadding={0} align="center">
                                        <tr>
                                            <td valign="top">
                                                <div style={{ float:'left',width:'200px'}}>
                                                    <div style={{ fontFamily:'Arial, Helvetica, sans-serif', fontSize:'13px', color:'#143bb3', float:'left', textAlign:'left', marginLeft:'0px', fontWeight:'bold'}}>Resource Coordinator</div><br />
                                                    <select size={1} style={{ width:'200px'}} name="rc" value={singleOption} onChange={handleSingleChange }>
                                                    <option value="">--select RC--</option>
                                                    {options1.map(option1 => (
                                                    <option key={option1.id} value={option1.rcid}>
                                                        {option1.firstname} {option1.lastname}
                                                    </option>
                                                    ))}
                                                    </select>    
                                                </div>
                                            </td>
                                            <td><input name="asignconsultant" type="submit" value="<=Assign" style={{ marginLeft:'0px'}} class="btn btn-danger btn-md"/></td>
                                            <td>
                                                <div style={{ float:'left', width:'200px'}}>
                                                    <div style={{fontFamily:'Arial, Helvetica, sans-serif', fontSize:'13px', color:'#143bb3', float:'left', textAlign:'left', marginLeft:'0px',fontWeight:'bold'}}> <label for="Rc_fname">Available Consultants</label></div><br />
                                                    <select size="1" style={{ width:'200px', height:'200px',textAlign:'left'}} multiple name="consultants" value={multipleOptions} onChange={handleMultipleChange}>
                                                    <option value="">--Select Consultants--</option>
                                                    {options2.map(option2 => (
                                                    <option key={option2.id} value={option2.cid}>
                                                        {option2.fname} {option2.lname}
                                                    </option>
                                                    ))}
                                                    </select>    
                                                </div>
                                            </td>
                                            </tr>
                                        </table>
								        </form> 
                                        <br/>
                                        <div style={{ fontFamily:'Arial', fontSize:'13px', backgroundColor:'#ccc', padding:'3px',width:'430px', borderRadius:'3px', textAlign:'left'}}><b>Note:</b>&nbsp;&nbsp; To select multiple consultants press "ctrl +  select" from the list.</div>
							        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        </section>
    );
}

export default Asigntoconsultant;