import React from "react";
import { a } from 'react-router-dom';
import "../css/header.css";
import 'bootstrap/dist/css/bootstrap.css';
import service1 from '../images/services/01.png';
import service2 from '../images/services/02.png';
import service3 from '../images/services/03.png';
import service4 from '../images/services/04.png';
import { BorderBottom } from "react-bootstrap-icons";

function Servicespart() {

    return (
        <section id="middleSection">
            <div className="container" style={{ marginTop:'10px'}}>
                <div className="col-md-12">
                    <div className="row" style={{ textAlign:'center',lineHeight:'23px'}}>
                        <div className="col-md-3" >
                            <div className="well well-small" >
                                <h5 style={{ padding:'10px'}}><img src={service3} alt=""/><span>Solution Integration</span> </h5>
                                <div style={{marginBottom:'10px'}}><p>Transnational Solution Integration (SI)<br/> services enable clients to identify,<br/> develop, and implement the most <br/>appropriate solutions which are <br/>equipped to meet their...</p><br/><a href="#" className="link_button2">Click Here</a></div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="well well-small">
                                <h5 style={{ padding:'10px'}}><img src={service2} alt=""/><span>App Development</span></h5>
                                <div style={{marginBottom:'10px'}}><p>With design and development<br/> expertise in diverse platforms, usage<br/> of best-of-breed tools and<br/> techniques, combined with our<br/> state-of-the-art software development<br/> facilities...</p><a href="#" className="link_button2">Click Here</a></div>
                            </div>  
                        </div>  
                        <div className="col-md-3">
                            <div className="well well-small">
                                <h5 style={{ padding:'10px'}}><img src={service1} alt=""/><span>Workforce Solutions</span></h5>
                                <div style={{marginBottom:'10px'}}><p>Transnational offers a full range of<br/> workforce solutions to help analyze<br/> your business requirements for<br/> effective implementation of software<br/> solutions. Within this domain, we<br/> provide...</p><a href="#" className="link_button2">Click Here</a></div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="well well-small">
                                <h5 style={{ padding:'10px'}}><img src={service4} style={{ height:'73px'}} alt=""/><span>Managed Services</span></h5>
                                <div style={{marginBottom:'10px'}}><p>Transnational provides services that<br/> manage a infrastructure and<br/> applications so as to allow<br/> customers to concentrate on their<br/> core business. Managed service<br/> provides...</p><a href="#" className="link_button2">Click Here</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Servicespart