import React, { useState,useEffect,useRef  } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import tnsuslogo from "../images/tnsuslogo.jpg";
import print from "../images/print.jpg";


const Viewjob = () => {
    let {id} = useParams();
    const [viewjob,setViewjob]=useState({});
    const [string, setString] = useState();

    const Print = () =>{     
        let printContents = document.getElementById('printme').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents; 
    }
        
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(`https://api.transnational.ai/api/viewjob/${id}`);
                setViewjob(response.data)
                setString(stripHtmlTags(response.data.description))
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    },[])

    const stripHtmlTags = (html) => {
        return html.replace(/<[^>]*>/g, '');
    };  

         
    return (
        
        <div className="container" id='printme' style={{ marginTop:'100px'}}> 
        <br />
        <div className={'jobdet'}>Job Details</div>
        <hr  color="#FF6600" size="0.5" />
        <div className="content" style={{ border:'none', border:'1px solid #ccc' }}>
        <table border={0} cellSpacing={5} cellPadding={7} >
        <tbody>
        <tr>
            <td width={200} valign={'top'}><div ><b>Date Posted </b></div></td>
            <td width={10} valign={'top'}> : </td>
            <td width={900}><div className={'content_bold'}>{viewjob.postingdate}</div></td>
        </tr>
        <tr>
            <td valign={'top'}><div><b>Job Title</b></div></td>
            <td width={10} valign={'top'}> : </td>
            <td><div className={'content_bold'}>{viewjob.title}</div></td>
        </tr>
        <tr>
            <td valign={'top'}><div ><b>Job Description </b></div></td>
            <td width={10} valign={'top'}> : </td>
            <td valign={'top'}><div >{string}</div></td>
        </tr>
        <tr>
            <td width={200} valign={'top'}><div ><b> Experience</b> </div></td>
            <td width={10} valign={'top'}> : </td>
            <td width={900}><div className={'content_bold'}>{viewjob.experience}</div></td>
        </tr> 
        <tr>
            <td valign={'top'}><div ><b>No. of Positions</b> </div></td>
            <td width={10} valign={'top'}> : </td>
            <td><div className={'content_bold'}>{viewjob.positions}</div></td>
        </tr>
        <tr>
            <td valign={'top'}><div ><b> Education</b></div></td>
            <td width={10} valign={'top'}> : </td>
            <td><div className={'content_bold'}>{viewjob.education}</div></td>
        </tr>
        <tr>
            <td width={200} valign={'top'}><div ><b>Location</b>  </div></td>
            <td width={10} valign={'top'}> : </td>
            <td width={900}><div className={'content_bold'}>{viewjob.city}, {viewjob.state} - {viewjob.zipcode}</div></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td valign={'top'} align={'right'}><img src={tnsuslogo} style={{ marginLeft:"690px"}}/></td>
        </tr>
        </tbody>
        </table>
        </div>
        <div className="container">
            <div className='row' style={{ backgroundColor:'#ffffff'}}>
                <div className="col-md-11" style={{ marginTop:"5px",  fontFamily:"Arial",fontSize:"12px", fontWeight:"bold", align:"left"}}><strong>Note</strong> :  Please mail your resumes to : 8951 Cypress Waters Blvd, Ste #160, Coppell, TX – 75019
            Tel: 575-680-2535 | Fax: 786-664-6508.</div>
                <div className="col-md-1 tns1" style={{marginTop:"-15px",  fontFamily:"Arial",fontSize:"12px", fontWeight:"bold", align:"right"}} ><button onClick={Print} style={{ textDecoration:'none', border:'#ffffff'}}><img src={print} style={{ width:'28', height:'28', marginTop:'20px'}}  draggable="false" title="Print" /></button></div>
            </div>
        </div>
        <br className="clear" />  
        <br />
        <br />
        </div>
    );
};

export default Viewjob;
